#hero_video .wrapper,
.switchery,
.table.cart-list td {
    vertical-align: middle
}

@media only screen and (min-width:992px) {
    nav#menu.main-menu {
        display: block!important
    }
    #mm-menu.main-menu,
    header .btn_mobile {
        display: none!important
    }
    .main-menu {
        position: relative;
        z-index: 9;
        width: auto;
        top: 12px;
        right: 15px;
        float: right;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        color: #fff
    }
    .main-menu ul,
    .main-menu ul li {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0
    }
    .main-menu ul a,
    .main-menu ul li a {
        position: relative;
        margin: 0;
        display: block;
        padding: 5px 10px;
        white-space: nowrap;
        color: #000000;
    }
    .main-menu>ul>li span>a {
        color: #fff;
        padding: 0 8px 15px;
        font-size: 14px;
        font-size: .875rem;
        font-weight: 500
    }
    .main-menu>ul>li span:hover>a {
        opacity: .7
    }
    header.sticky .main-menu>ul>li span>a {
        color: #000;
    }
    header.sticky .main-menu>ul>li span:hover>a {
        opacity: 1;
        color: #fc5b62
    }
    .main-menu ul ul {
        position: absolute;
        z-index: 1;
        visibility: hidden;
        left: 3px;
        top: 100%;
        margin: 0;
        display: block;
        padding: 0;
        background: #fff;
        min-width: 200px;
        -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, .175);
        -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, .175);
        transform: scale(.4);
        transform-origin: 10% top;
        opacity: 0;
        -moz-transition: all .2s ease;
        -o-transition: all .2s ease;
        -webkit-transition: all .2s ease;
        -ms-transition: all .2s ease;
        transition: all .2s ease;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px
    }
    .main-menu ul ul:before {
        bottom: 100%;
        left: 15%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #fff;
        border-width: 7px;
        margin-left: -7px
    }
    .main-menu ul ul li {
        display: block;
        height: auto;
        padding: 0
    }
    .main-menu ul ul li a {
        font-size: 13px;
        font-size: .8125rem;
        color: #555;
        border-bottom: 1px solid #ededed;
        display: block;
        padding: 15px 10px;
        line-height: 1
    }
    .main-menu ul li:hover>ul {
        padding: 0;
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition-delay: .2s;
        transition-delay: .2s;
        -webkit-transition-duration: 0s, .2s, .2s;
        transition-duration: 0s, .2s, .2s
    }
    .main-menu ul ul li:first-child a:hover {
        -webkit-border-radius: 3px 3px 0 0;
        -moz-border-radius: 3px 3px 0 0;
        -ms-border-radius: 3px 3px 0 0;
        border-radius: 3px 3px 0 0
    }
    .main-menu ul ul li:last-child a {
        border-bottom: none
    }
    .main-menu ul ul li:last-child a:hover {
        -webkit-border-radius: 0 0 3px 3px;
        -moz-border-radius: 0 0 3px 3px;
        -ms-border-radius: 0 0 3px 3px;
        border-radius: 0 0 3px 3px
    }
    .main-menu ul ul li:hover>a {
        background-color: #f9f9f9;
        color: #fc5b62;
        padding-left: 18px
    }
}

.main-menu ul ul.second_level_right {
    left: auto;
    right: 3px
}

.main-menu ul ul.second_level_right:before {
    right: 15%;
    left: auto
}

.main-menu ul ul li span a {
    font-weight: 400!important
}

.main-menu ul ul li span a:hover {
    color: #fc5b62;
    padding-left: 18px;
    opacity: 1
}

.main-menu ul ul li span a:after {
    font-family: ElegantIcons;
    content: "\35";
    float: right;
    font-size: 16px;
    font-size: 1rem;
    margin-top: -2px
}

.marker_info,
body {
    font-family: Poppins, Helvetica, sans-serif
}

.main-menu ul ul ul {
    position: absolute;
    border-top: 0;
    z-index: 1;
    height: auto;
    left: 100%;
    top: 0;
    margin: 0;
    padding: 0;
    background: #fff;
    min-width: 190px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

.main-menu ul ul ul.third_level_left {
    right: 100%;
    left: auto
}

.main-menu ul ul ul li a {
    border-bottom: 1px solid #ededed!important
}

.main-menu ul ul ul:before {
    border-width: 0;
    margin-left: 0
}

@media only screen and (max-width:991px) {
    nav#menu {
        display: none!important
    }
    nav#menu.active {
        display: block!important;
        margin-top: 50px;
        background-color: white;
        height: 100vh;
        width: 100vw;
        position:fixed;
        text-align: center;
        padding-top: 50px;
    }
    nav#menu.active ul li{
        font-size: 22px;
        margin-bottom: 15px;
    }
    .mm-menu {
        background: #fff
    }
    .main-menu {
        top: 0;
        right: 0;
        float: none
    }
    ul.mm-listview {
        line-height: 25px
    }
    ul.mm-listview li a {
        color: #555!important;
        display: block
    }
}

#menu.fake_menu {
    display: none!important;
    visibility: hidden!important
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: #fff;
    z-index: 99999999999!important
}

[data-loader=circle-side] {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
    -webkit-animation: circle infinite .95s linear;
    -moz-animation: circle infinite .95s linear;
    -o-animation: circle infinite .95s linear;
    animation: circle infinite .95s linear;
    border: 2px solid #fc5b62;
    border-top-color: rgba(0, 0, 0, .2);
    border-right-color: rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .2);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%
}

hr,
hr.large {
    border-color: #ededed
}

@-webkit-keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-moz-keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-o-keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

body {
    background: #f8f8f8;
    font-size: .875rem;
    line-height: 1.6;
    color: #555
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333
}

p {
    margin-bottom: 30px
}

strong {
    font-weight: 600
}

label {
    font-weight: 500;
    margin-bottom: 3px
}

.main_title_2 p,
.main_title_3 p {
    font-weight: 300;
    line-height: 1.4
}

hr {
    margin: 30px 0
}

hr.large {
    border-top-width: 4px;
    margin-top: 60px
}

@media (max-width:991px) {
    hr.large {
        margin-top: 45px
    }
}

@media (max-width:767px) {
    hr.large {
        margin-bottom: 45px
    }
}

ol,
ul {
    list-style: none;
    margin: 0 0 25px;
    padding: 0
}

.main_title {
    text-align: center;
    margin-bottom: 45px;
    position: relative
}

.main_title span {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    margin: auto auto 20px;
    opacity: 1;
    background-color: #999;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all .6s;
    transition: all .6s
}

.btn_1,
a,
a.btn_1 {
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    outline: 0;
    text-decoration: none
}

.main_title.animated span {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.main_title_2 {
    text-align: center;
    margin-bottom: 45px;
    position: relative
}

.main_title_2 span {
    width: 120px;
    height: 2px;
    background-color: #e1e1e1;
    display: block;
    margin: auto
}

.main_title_2 span em {
    width: 60px;
    height: 2px;
    background-color: #fc5b62;
    display: block;
    margin: auto
}

@media (max-width:767px) {
    .main_title_2 {
        margin-bottom: 10px
    }
    .main_title_2 h2 {
        font-size: 26px;
        font-size: 1.625rem
    }
}

.main_title_2 h2,
.main_title_2 h3 {
    margin: 25px 0 0
}

@media (max-width:767px) {
    .main_title_2 h3 {
        font-size: 24px;
        font-size: 1.5rem
    }
}

.main_title_2 p {
    font-size: 24px;
    font-size: 1.5rem
}

.main_title_3 {
    margin-bottom: 25px;
    position: relative
}

.main_title_3 span {
    width: 120px;
    height: 2px;
    background-color: #e1e1e1;
    display: block
}

.main_title_3 span em {
    width: 60px;
    height: 2px;
    background-color: #fc5b62;
    display: block
}

@media (max-width:767px) {
    .main_title_2 p {
        font-size: 18px;
        font-size: 1.125rem
    }
    .main_title_3 {
        margin-bottom: 10px
    }
}

.main_title_3 h2,
.main_title_3 h3 {
    font-size: 23px;
    font-size: 1.4375rem;
    margin: 25px 0 0
}

@media (max-width:767px) {
    .main_title_3 h2,
    .main_title_3 h3 {
        font-size: 21px;
        font-size: 1.3125rem
    }
}

.main_title_3 p {
    font-size: 18px;
    font-size: 1.125rem
}

h3.slide_typo {
    white-space: nowrap;
    color: #fff!important;
    font-weight: 400;
    font-size: 60px;
    font-size: 3.75rem
}

.btn_1,
.btn_map_in,
a.btn_1,
a.btn_map,
a.btn_map_in {
    font-weight: 600;
    line-height: 1
}

.slide_typo_2 {
    color: #fff;
    font-size: 24px;
    font-size: 1.5rem;
    white-space: nowrap;
    text-transform: uppercase
}

a {
    color: #fc5b62;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

a:focus,
a:hover {
    color: #111;
    text-decoration: none;
    outline: 0
}

.btn_1,
a.btn_1 {
    border: none;
    background: #fc5b62;
    cursor: pointer;
    display: inline-block;
    padding: 15px 30px;
    color: #fff;
    text-align: center;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px
}

.btn_map_in,
.closebt,
a.btn_map_in {
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    cursor: pointer
}

.btn_1:hover,
a.btn_1:hover {
    background: #0054a6;
    color: #fff
}

.btn_1.full-width,
a.btn_1.full-width {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 5px
}

.btn_1.full-width.purchase,
a.btn_1.full-width.purchase {
    background-color: #32a067
}

.btn_1.full-width.purchase:hover,
a.btn_1.full-width.purchase:hover {
    background-color: #0054a6
}

.btn_1.full-width.wishlist,
a.btn_1.full-width.wishlist {
    border-color: #555;
    color: #555
}

.btn_1.medium,
a.btn_1.medium {
    font-size: 16px;
    font-size: 1rem;
    padding: 18px 40px
}

.btn_1.rounded,
a.btn_1.rounded {
    -webkit-border-radius: 25px!important;
    -moz-border-radius: 25px!important;
    -ms-border-radius: 25px!important;
    border-radius: 25px!important
}

.btn_1.outline,
a.btn_1.outline {
    border: 2px solid #fc5b62;
    color: #fc5b62;
    padding: 11px 40px;
    background: 0 0
}

.btn_1.outline:hover,
a.btn_1.outline:hover {
    background: #0054a6;
    color: #fff;
    border-color: #0054a6
}

.btn_1.outline:focus,
a.btn_1.outline:focus {
    outline: 0
}

.closebt {
    transition: all .3s ease-in-out;
    position: absolute;
    font-size: 24px;
    font-size: 1.5rem;
    top: 10px;
    right: 15px;
    color: #fff;
    opacity: .6;
    width: 30px;
    height: 30px
}

.closebt:hover {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 1
}

.btn_home_align {
    text-align: right;
    margin-bottom: 25px
}

@media (max-width:767px) {
    .btn_home_align {
        text-align: center;
        margin-bottom: 25px
    }
}

a.btn_map {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 8px 12px;
    margin-bottom: 5px;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, .08);
    font-size: 12px;
    font-size: .75rem;
    color: rgba(0, 0, 0, .5)
}

a.btn_map:hover {
    color: #fc5b62
}

a.btn_map:before {
    font-family: ElegantIcons;
    content: "\e081";
    display: inline-block;
    margin-right: 5px
}

.btn_map_in,
a.btn_map_in {
    border: none;
    background: #fc5b62;
    outline: 0;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    display: inline-block;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px
}

header.header .logo_sticky,
header.header.sticky .logo_normal {
    display: none
}

footer ul li a,
header.header.sticky {
    -moz-transition: all .3s ease-in-out;
    color:#000;
}

.btn_map_in:hover,
a.btn_map_in:hover {
    background: #0054a6;
    color: #fff
}

.cTop {
    margin-top: 120px;
}

header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px 30px;
    z-index: 99999999;
    border-bottom: 1px solid rgba(255, 255, 255, 0)
}
header.header.sec-header {
    background-color: #fff;
    padding: 15px;
    color: black;
}
header.header.sec-header.border-none {
    border: none;
}
header.header.sec-header .logo_normal {
    display: none;
}
header.header.sec-header .logo_sticky{
    display: block;
}

header.header.sec-header .main-menu>ul>li span>a {
    color: #004dda;
    padding: 0 8px 15px;
    font-size: 17px;
    font-weight: bold;
}
@media (max-width:767px) {
    header {
        padding: 15px 15px 5px
    }

}

header.header.sticky {
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: rgba(97, 97, 97, 0.180);
    padding: 15px
}

@media (max-width:991px) {
    header.header.sticky {
        padding: 15px
    }
}

header.header.sticky .logo_sticky {
    display: inline-block
}

ul#top_menu {
    float: right;
    margin: 0 0 0 10px;
    padding: 0;
    font-size: 13px;
    font-size: .8125rem
}

ul#top_menu li {
    float: left;
    margin: 0 15px 0 0;
    line-height: 1
}

ul#top_menu li:last-child {
    margin-right: 0;
    position: relative;
    top: 0
}

@media (max-width:767px) {
    ul#top_menu li:first-child {
        display: none
    }
}

ul#top_menu li a {
    color: #fff
}

ul#top_menu li a:hover {
    color: #fff;
    opacity: .7
}

ul#top_menu li a.cart-menu-btn,
ul#top_menu li a.login,
ul#top_menu li a.wishlist_bt_top {
    display: block;
    width: 22px;
    height: 23px;
    position: relative;
    top: 8px;
    -moz-transition: opacity .5s ease;
    -o-transition: opacity .5s ease;
    -webkit-transition: opacity .5s ease;
    -ms-transition: opacity .5s ease;
    transition: opacity .5s ease
}

@media (max-width:991px) {
    ul#top_menu {
        margin: -2px 0 0 10px
    }
    ul#top_menu li {
        margin: 0 0 0 10px
    }
    ul#top_menu li a.cart-menu-btn,
    ul#top_menu li a.login,
    ul#top_menu li a.wishlist_bt_top {
        top: 2px
    }
}

ul#top_menu li a.cart-menu-btn:before,
ul#top_menu li a.login:before,
ul#top_menu li a.wishlist_bt_top:before {
    font-family: Glyphter;
    font-size: 21px;
    font-size: 1.3125rem;
    color: #fff;
    text-indent: 0;
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 400;
    line-height: 1
}

.sticky ul#top_menu li a.cart-menu-btn:before,
.sticky ul#top_menu li a.login:before,
.sticky ul#top_menu li a.wishlist_bt_top:before {
    color: #444
}

ul#top_menu li a.wishlist_bt_top {
    text-indent: -9999px;
    overflow: hidden
}

ul#top_menu li a.wishlist_bt_top:before {
    content: '\0043'
}

ul#top_menu li a.login {
    text-indent: -9999px;
    overflow: hidden
}

ul#top_menu li a.login:before {
    content: '\0042'
}

ul#top_menu li a.cart-menu-btn {
    top: 6px
}

@media (max-width:991px) {
    ul#top_menu li a.cart-menu-btn {
        top: 0
    }
}

ul#top_menu li a.cart-menu-btn:before {
    content: '\0041'
}

ul#top_menu li a.cart-menu-btn strong {
    font-size: 11px;
    font-size: .6875rem;
    width: 15px;
    height: 15px;
    display: block;
    background-color: #333;
    color: #fff;
    position: relative;
    bottom: -15px;
    right: -10px;
    text-align: center;
    line-height: 17px!important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    margin: 0;
    padding: 0
}

ul#top_menu li a.logged {
    display: block;
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    top: 6px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: opacity .5s ease;
    -o-transition: opacity .5s ease;
    -webkit-transition: opacity .5s ease;
    -ms-transition: opacity .5s ease;
    transition: opacity .5s ease
}

#newsletter input[type=submit],
footer ul li a {
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out
}

ul#top_menu li a.logged img {
    width: 26px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

@media (max-width:991px) {
    ul#top_menu li a.logged {
        top: 0
    }
}

.dropdown-user .dropdown-menu {
    min-width: 0;
    width: 150px;
    top: 160%;
    margin-left: -115px;
    font-weight: 400;
    text-shadow: none;
    text-transform: none!important;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .02);
    border-left: 1px solid rgba(0, 0, 0, .02);
    -webkit-box-shadow: 3px 2px 7px 1px rgba(0, 0, 0, .08);
    -moz-box-shadow: 3px 2px 7px 1px rgba(0, 0, 0, .08);
    box-shadow: 3px 2px 7px 1px rgba(0, 0, 0, .08);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 0
}

.dropdown-user .dropdown-menu:before {
    bottom: 100%;
    right: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: -7px
}

.dropdown-user .dropdown-menu ul {
    padding: 0;
    margin: 0!important;
    list-style: none
}

.dropdown-user .dropdown-menu ul li {
    border-bottom: 1px solid #ededed;
    margin: 0 0 15px;
    width: 100%;
    display: inline-block;
    font-size: 13px;
    font-size: .8125rem
}

@media (max-width:991px) {
    .dropdown-user .dropdown-menu ul li {
        margin: 0!important
    }
}

.dropdown-user .dropdown-menu ul li a {
    color: #444!important;
    padding: 15px 8px;
    display: block
}

.dropdown-user .dropdown-menu ul li a:hover {
    color: #fc5b62!important;
    opacity: 1!important;
    padding-left: 15px
}

.dropdown-user .dropdown-menu ul li:last-child {
    border-bottom: none;
    margin-bottom: 0
}

header.sticky .hamburger-inner,
header.sticky .hamburger-inner::after,
header.sticky .hamburger-inner::before {
    background-color: #444
}

#logo {
    float: left
}

@media (max-width:991px) {
    .dropdown-user .dropdown-menu ul li:first-child {
        display: block!important
    }
    #logo {
        float: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center
    }
    #logo img {
        width: auto;
        height: 30px;
        margin: 12px 0 0
    }
}

.hero_in {
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
    text-align: center;
    color: #fff
}

@media (max-width:767px) {
    .hero_in {
        height: 350px
    }
}

.hero_in.general:before {
    /* background: url(../img/hero_in_bg_2.jpg) center center no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.hero_in.general .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6)
}

.hero_in.tours:before {
    /* background: url(../img/hero_in_bg_1.jpg) center center no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.hero_in.tours .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6)
}

.hero_in.hotels:before {
    /* background: url(../img/hero_in_bg_4.jpg) center center no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.hero_in.hotels .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6)
}

.hero_in.restaurants:before {
    /* background: url(../img/hero_in_bg_5.jpg) center center no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.hero_in.restaurants .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6)
}

.hero_in.tours_detail {
    height: 550px
}

@media (max-width:575px) {
    .hero_in.tours_detail {
        height: 500px
    }
}

.hero_in.tours_detail:before {
    /* background: url(../img/hero_in_tours_detail.jpg) center center no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.hero_in.tours_detail .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6)
}

.hero_in.hotels_detail {
    height: 550px
}

@media (max-width:575px) {
    .hero_in.hotels_detail {
        height: 500px
    }
}

.hero_in.hotels_detail:before {
    /* background: url(../img/hero_in_hotels_detail.jpg) center center no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.hero_in.hotels_detail .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6)
}

.hero_in.restaurants_detail {
    height: 550px
}

@media (max-width:575px) {
    .hero_in.restaurants_detail {
        height: 500px
    }
}

.hero_in.restaurants_detail:before {
    /* background: url(../img/hero_in_restaurants_detail.jpg) center center no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.hero_in.restaurants_detail .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6)
}

.hero_in.contacts:before {
    /* background: url(../img/hero_in_bg_3.jpg) center center no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.hero_in.contacts .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6)
}

.hero_in:before {
    animation: pop-in 5s .3s cubic-bezier(0, .5, 0, 1) forwards;
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1
}

.hero_in .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%
}

.hero_in .wrapper h1 {
    color: #fff;
    font-size: 42px;
    font-size: 2.625rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 30px
}

@media (max-width:767px) {
    .hero_in .wrapper h1 {
        font-size: 32px;
        font-size: 2rem
    }
}

.hero_in .wrapper h1 span {
    width: 80px;
    height: 4px;
    background-color: #FFC107;
    display: block;
    margin: auto auto 20px;
    border-radius: 10px;
    opacity: 1
}

.follow_us ul li,
footer ul li a i,
footer ul.contacts li a i {
    margin-right: 10px
}

.hero_in .wrapper p {
    font-weight: 300;
    margin-bottom: 0;
    font-size: 24px;
    font-size: 1.5rem
}

.hero_in .wrapper p strong {
    font-weight: 600
}

.hero_in a.btn_photos {
    position: absolute;
    left: 20px;
    bottom: 20px;
    background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .2);
    line-height: 1;
    padding: 10px 15px;
    color: #444;
    font-weight: 500
}

.hero_in a.btn_photos:hover {
    color: #fc5b62
}

@keyframes pop-in {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1)
    }
}

.hero_in h1 {
    animation-delay: .8s;
    -webkit-animation-delay: .8s;
    -moz-animation-delay: .8s
}

.hero_in p {
    animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    animation-duration: .3s;
    -webkit-animation-duration: .3s;
    -moz-animation-duration: .3s
}

main {
    background-color: #f8f8f8;
    position: relative;
    z-index: 1
}

footer {
    background-color: #121921;
    color: #fbfbfb;
    color: rgba(255, 255, 255, .7)
}

footer h5 {
    color: #fff;
    margin: 25px 0;
    font-size: 18px;
    font-size: 1.125rem
}

@media (max-width:575px) {
    footer h5 {
        margin: 25px 0 10px
    }
}

footer ul li {
    margin-bottom: 5px
}

footer ul li a {
    transition: all .3s ease-in-out;
    display: inline-block;
    position: relative;
    color: #fff;
    opacity: .7
}

footer ul li a:hover {
    color: #fc5b62;
    opacity: 1
}

footer ul li a i {
    color: #fff
}

footer ul.links li a:hover {
    -webkit-transform: translate(5px, 0);
    -moz-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    -o-transform: translate(5px, 0);
    transform: translate(5px, 0)
}

footer ul.links li a:hover:after {
    opacity: 1;
    color: #fc5b62
}

footer ul.links li a:after {
    font-family: ElegantIcons;
    content: "\24";
    position: absolute;
    margin-left: 5px;
    top: 1px;
    opacity: 0;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease
}

footer hr {
    opacity: .1
}

footer #copy {
    text-align: right;
    font-size: 13px;
    font-size: .8125rem;
    opacity: .7
}

@media (max-width:767px) {
    footer #copy {
        text-align: left;
        margin: 5px 0 20px
    }
}

#newsletter .loader {
    position: absolute;
    right: -20px;
    top: 11px;
    color: #fff;
    font-size: 12px;
    font-size: .75rem
}

#newsletter h6 {
    color: #fff;
    margin: 15px 0
}

#newsletter .form-group {
    position: relative
}

#newsletter input[type=email] {
    border: 0;
    height: 40px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    padding-left: 15px;
    font-size: 14px;
    font-size: .875rem;
    background-color: #f3f3f3;
    background-color: rgba(255, 255, 255, .06);
    color: #fff
}

#newsletter input[type=email]:focus {
    border: 0;
    box-shadow: none
}

#newsletter input[type=submit] {
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 13px;
    font-size: .8125rem;
    font-weight: 600;
    top: 0;
    border: 0;
    padding: 0 12px;
    height: 40px;
    cursor: pointer;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    background-color: #fc5b62;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    outline: 0
}

#newsletter input[type=submit]:hover {
    background-color: #FFC107;
    color: #222
}

.follow_us {
    margin-top: 15px;
    animation-delay: 1.1s;
    -webkit-animation-delay: 1.1s;
    -moz-animation-delay: 1.1s
}

.follow_us h5 {
    color: #fff
}

.follow_us ul li {
    display: inline-block;
    font-size: 20px;
    font-size: 1.25rem
}

.follow_us ul li:first-child {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 13px;
    font-size: .8125rem
}

.follow_us ul li a {
    color: #fff;
    opacity: .7
}

.follow_us ul li a:hover,
ul#additional_links li a:hover {
    opacity: 1
}

ul#footer-selector {
    margin: 0;
    padding: 0;
    list-style: none
}

ul#footer-selector li {
    float: left;
    margin-right: 10px
}

@media (max-width:575px) {
    ul#footer-selector li:last-child {
        margin-top: 5px
    }
}

ul#additional_links {
    margin: 0;
    padding: 8px 0 0;
    color: #555;
    font-size: 13px;
    font-size: .8125rem;
    float: right
}

@media (max-width:991px) {
    ul#additional_links {
        float: none;
        margin-top: 10px
    }
}

ul#additional_links li {
    display: inline-block;
    margin-right: 15px
}

ul#additional_links li:first-child {
    margin-right: 20px
}

ul#additional_links li:last-child:after {
    content: ""
}

ul#additional_links li span {
    color: #fff;
    opacity: .5
}

ul#additional_links li a {
    color: #fff;
    opacity: .5;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

ul#additional_links li:after {
    content: "|";
    font-weight: 300;
    position: relative;
    left: 10px
}

.styled-select {
    width: 100%;
    overflow: hidden;
    height: 30px;
    line-height: 30px;
    border: none;
    background-color: #f3f3f3;
    background-color: rgba(255, 255, 255, .05);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding-right: 35px;
    position: relative
}

.styled-select#currency-selector:after,
.styled-select#lang-selector:after {
    font-family: ElegantIcons;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 0
}

.styled-select#lang-selector:after {
    content: "\e0e2"
}

.styled-select#currency-selector:after {
    content: "\33"
}

.styled-select select {
    background: 0 0;
    width: 110%;
    padding-left: 10px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    height: 30px;
    color: #ccc;
    font-size: 12px;
    font-size: .75rem;
    margin: 0;
    font-weight: 500;
    -moz-appearance: window;
    -webkit-appearance: none;
    cursor: pointer;
    outline: 0
}

.styled-select select:focus {
    color: #fff;
    outline: 0;
    box-shadow: none
}

.styled-select select::-ms-expand {
    display: none
}

.styled-select#lang-selector select option {
    color: #555
}

.wow {
    visibility: hidden
}

@font-face {
    font-family: flexslider-icon;
    font-weight: 400;
    font-style: normal
}

.flex-container a:hover,
.flex-slider a:hover {
    outline: 0
}

.flex-control-nav,
.flex-direction-nav,
.slides,
.slides>li {
    margin: 0;
    padding: 0;
    list-style: none
}

.flex-pauseplay span {
    text-transform: capitalize
}

.flexslider {
    padding: 0;
    margin: 0;
    background: #fff;
    position: relative;
    zoom: 1
}

.flexslider .slides>li {
    display: none;
    -webkit-backface-visibility: hidden
}

.flexslider .slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

html[xmlns] .flexslider .slides {
    display: block
}

* html .flexslider .slides {
    height: 1%
}

.no-js .flexslider .slides>li:first-child {
    display: block
}

.flexslider .slides {
    zoom: 1
}

.flexslider .slides img {
    width: 100%;
    display: block;
    height: auto;
    -moz-user-select: none
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease
}

.loading .flex-viewport {
    max-height: 300px
}

.carousel li {
    margin-right: 5px
}

.flex-direction-nav {
    position: absolute;
    top: 45%;
    width: 100%;
    z-index: 99
}

.flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(255, 255, 255, .8);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.flex-direction-nav a:before {
    font-family: flexslider-icon;
    font-size: 30px;
    display: inline-block;
    content: '\f001';
    color: rgba(255, 255, 255, .8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, .3)
}

#hero_video .wrapper h3,
#hero_video .wrapper p,
.hero_single.version_2 .wrapper h3,
.hero_single.version_2 .wrapper p {
    text-shadow: 4px 4px 12px rgba(0, 0, 0, .3)
}

.flex-direction-nav a.flex-next:before {
    content: '\f002'
}

.flex-direction-nav .flex-next {
    text-align: right
}

.flexslider:hover .flex-direction-nav .flex-prev {
    opacity: .7;
    left: 20px
}

.flexslider:hover .flex-direction-nav .flex-prev:hover {
    opacity: 1
}

.flexslider:hover .flex-direction-nav .flex-next {
    opacity: .7;
    right: 20px
}

.flexslider:hover .flex-direction-nav .flex-next:hover {
    opacity: 1
}

.flex-direction-nav .flex-disabled {
    opacity: 0!important;
    filter: alpha(opacity=0);
    cursor: default;
    z-index: -1
}

.flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: .8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000
}

.flex-pauseplay a:before {
    font-family: flexslider-icon;
    font-size: 20px;
    display: inline-block;
    content: '\f004'
}

.flex-pauseplay a:hover {
    opacity: 1
}

.flex-pauseplay a.flex-play:before {
    content: '\f003'
}

.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center
}

.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1
}

.flex-control-paging li a {
    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0, 0, 0, .5);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
    -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
    -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px
}

.flex-control-paging li a:hover {
    background: #333;
    background: rgba(0, 0, 0, .7)
}

.flex-control-paging li a.flex-active {
    background: #000;
    background: rgba(0, 0, 0, .9);
    cursor: default
}

.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden
}

.flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0
}

.flex-control-thumbs img {
    width: 100%;
    height: auto;
    display: block;
    opacity: .7;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease
}

.flex-control-thumbs img:hover {
    opacity: 1
}

.flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default
}

#slider.flexslider li {
    position: relative
}

#slider.flexslider li .meta {
    position: absolute;
    bottom: 35%;
    left: 60px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 500;
    z-index: 99;
    padding-right: 45px
}

#slider.flexslider li h3 {
    font-size: 36px;
    font-size: 2.25rem;
    color: #fff
}

@media (max-width:767px) {
    #slider.flexslider li .meta {
        left: 45px;
        bottom: auto;
        top: 50%
    }
    #slider.flexslider li h3 {
        font-size: 24px;
        font-size: 1.5rem
    }
}

#slider.flexslider li .info {
    display: flex;
    flex-direction: row
}

#slider.flexslider li .info p {
    margin-right: 7px;
    font-size: 16px;
    font-size: 1rem
}

#slider.flexslider li .info p,
#slider.flexslider li h3 {
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

#slider.flexslider li .meta a {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
    padding: 12px 20px
}

#slider.flexslider li.flex-active-slide .meta .info p,
#slider.flexslider li.flex-active-slide .meta h3 {
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

#slider.flexslider li.flex-active-slide .meta a {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn
}

#slider.flexslider li.flex-active-slide .meta .info p {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

.slider {
    position: relative
}

#slider.flexslider {
    overflow: hidden
}

#slider.flexslider .flex-direction-nav {
    opacity: 0;
    display: none
}

#slider.flexslider ul.slides li {
    height: 650px;
    background-color: #000
}

@media (max-width:1199px) {
    #slider.flexslider ul.slides li {
        height: 650px!important
    }
}

@media (max-width:767px) {
    #slider.flexslider li .info p {
        font-size: 14px;
        font-size: .875rem
    }
    #slider.flexslider ul.slides li {
        height: 500px!important
    }
}

#slider.flexslider ul.slides li img {
    opacity: .5;
    width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

@media (max-width:1199px) {
    #slider.flexslider ul.slides li img {
        height: 650px!important;
        width: auto
    }
}

@media (max-width:767px) {
    #slider.flexslider ul.slides li img {
        height: 500px!important;
        width: auto
    }
}

@font-face {
    font-family: weather;
    src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.eot);
    src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.eot?#iefix) format("embedded-opentype"), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.woff) format("woff"), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.ttf) format("truetype"), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/artill_clean_icons-webfont.svg#artill_clean_weather_iconsRg) format("svg");
    font-weight: 400;
    font-style: normal
}

.weather i {
    color: #fff;
    font-family: weather;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    text-transform: none
}

.panel-dropdown a:after,
.qtyDec,
.qtyInc {
    font-family: ElegantIcons
}

.weathericon-0:before {
    content: ":"
}

.weathericon-1:before {
    content: "p"
}

.weathericon-2:before {
    content: "S"
}

.weathericon-3:before {
    content: "Q"
}

.weathericon-4:before {
    content: "S"
}

.weathericon-5:before,
.weathericon-6:before,
.weathericon-7:before,
.weathericon-8:before {
    content: "W"
}

.weathericon-9:before {
    content: "I"
}

.weathericon-10:before {
    content: "W"
}

.weathericon-11:before,
.weathericon-12:before,
.weathericon-13:before,
.weathericon-14:before {
    content: "I"
}

.weathericon-15:before {
    content: "W"
}

.weathericon-16:before {
    content: "I"
}

.weathericon-17:before {
    content: "W"
}

.weathericon-18:before {
    content: "U"
}

.weathericon-19:before,
.weathericon-20:before,
.weathericon-21:before,
.weathericon-22:before,
.weathericon-23:before {
    content: "Z"
}

.weathericon-24:before,
.weathericon-25:before {
    content: "E"
}

.weathericon-26:before {
    content: "3"
}

.weathericon-27:before {
    content: "a"
}

.weathericon-28:before {
    content: "A"
}

.weathericon-29:before {
    content: "a"
}

.weathericon-30:before {
    content: "A"
}

.weathericon-31:before {
    content: "6"
}

.weathericon-32:before {
    content: "1"
}

.weathericon-33:before {
    content: "6"
}

.weathericon-34:before {
    content: "1"
}

.weathericon-35:before {
    content: "W"
}

.weathericon-36:before {
    content: "1"
}

.weathericon-37:before,
.weathericon-38:before,
.weathericon-39:before {
    content: "S"
}

.weathericon-40:before {
    content: "M"
}

.weathericon-41:before {
    content: "W"
}

.weathericon-42:before {
    content: "I"
}

.weathericon-43:before {
    content: "W"
}

.weathericon-44:before {
    content: "a"
}

.weathericon-45:before {
    content: "S"
}

.weathericon-46:before {
    content: "U"
}

.weathericon-47:before {
    content: "S"
}

#icon_drag_mobile {
    position: absolute;
    right: 20px;
    bottom: 20px;
    /* background: url(../img/drag_icon.svg) no-repeat; */
    width: 50px;
    height: 30px;
    opacity: .6;
    z-index: 99;
    display: none
}

@media (max-width:767px) {
    #icon_drag_mobile {
        display: block
    }
    #carousel_slider_wp {
        display: none
    }
}

#carousel_slider.flexslider .flex-direction-nav,
.custom-search-input-2 select {
    display: none
}

#carousel_slider_wp {
    background: 0 0;
    background: -webkit-linear-gradient(top, transparent, #000);
    background: linear-gradient(to bottom, transparent, #000);
    position: absolute;
    bottom: 0;
    z-index: 9;
    width: 100%;
    padding: 60px 60px 45px
}

#carousel_slider.flexslider {
    overflow: hidden;
    background-color: transparent
}

#carousel_slider.flexslider ul.slides li {
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    background-color: #000;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .5);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .5);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .5);
    overflow: hidden
}

#carousel_slider.flexslider ul.slides li:hover img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2)
}

#carousel_slider.flexslider ul.slides li img {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .4;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

#carousel_slider.flexslider ul.slides li .caption {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    padding: 20px;
    color: #fff
}

#carousel_slider.flexslider ul.slides li .caption h3 {
    font-size: 21px;
    font-size: 1.3125rem;
    color: #fff;
    font-weight: 500
}

#carousel_slider.flexslider ul.slides li .caption h3 span {
    margin: 3px 0 0;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-size: .75rem
}

#carousel_slider.flexslider ul.slides li .caption small {
    opacity: 0;
    -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
    -ms-transition: all .3s cubic-bezier(.77, 0, .175, 1);
    -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
    transition: all .3s cubic-bezier(.77, 0, .175, 1);
    position: absolute;
    bottom: 10px;
    left: 20px;
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    font-size: .875rem;
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    -o-transform: translate(0, 10px);
    transform: translate(0, 10px)
}

#carousel_slider.flexslider ul.slides li .caption small strong {
    float: right;
    display: inline-block;
    text-align: right;
    margin: 0 40px 0 0;
    padding: 3px 0 0
}

#hero_video,
.hero_single {
    text-align: center;
    color: #fff
}

#carousel_slider.flexslider ul.slides li .caption small strong h6 {
    color: #fff;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    font-size: .875rem
}

#carousel_slider.flexslider ul.slides li:hover .caption small {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

a#moveDown {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9999;
    font-size: 32px;
    font-size: 2rem;
    color: #fff;
    animation-duration: 6s;
    -webkit-animation-duration: 6s;
    -moz-animation-duration: 6s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite
}

.header-video {
    position: relative;
    overflow: hidden;
    background: #000
}

@media (max-width:991px) {
    .header-video {
        height: 620px!important
    }
}

#hero_video,
iframe {
    height: 100%;
    width: 100%
}

#hero_video {
    position: relative;
    background-size: cover;
    font-size: 16px;
    font-size: 1rem;
    z-index: 99;
    display: table
}

#hero_video .wrapper {
    display: table-cell
}

#hero_video .wrapper h3 {
    color: #fff;
    font-size: 42px;
    font-size: 2.625rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0
}

@media (max-width:767px) {
    #hero_video .wrapper h3 {
        font-size: 26px;
        font-size: 1.625rem;
        margin-top: 60px
    }
}

@media (max-width:575px) {
    #hero_video .wrapper h3 {
        font-size: 22px;
        font-size: 1.375rem
    }
}

#hero_video .wrapper p {
    font-weight: 300;
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.4
}

#hero_video .wrapper p strong {
    font-weight: 600
}

@media (max-width:767px) {
    #hero_video .wrapper p {
        padding: 0;
        font-size: 14px;
        font-size: .875rem
    }
}

video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .4;
    width: 100%
}

.header-video--media,
.teaser-video {
    width: 100%;
    height: auto
}

@media (max-width:1024px) {
    #hero_video {
        /* background: url(../img/home_section_1.jpg) #ccc; */
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover
    }
    #hero_video>div {
        background-color: #000;
        background-color: rgba(0, 0, 0, .5)
    }
    .header-video {
        background: 0 0
    }
}

.hero_single {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 0
}

.hero_single .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%
}

.hero_single.start_bg_zoom:before {
    animation: pop-in 9s .2s cubic-bezier(0, .8, 0, 4) forwards;
    background-color: #FFF;
    content: "";
    /* https://images.unsplash.com/photo-1459749411175-04bf5292ceea?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80*/
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-image: url('https://vignette.wikia.nocookie.net/zombieescape/images/9/98/Ze_predator_ultimate_v3_css.png/revision/latest?cb=20120105193018');
}

.hero_single .wrapper h3 {
    color: #fff;
    font-size: 52px;
    font-size: 3.25rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 700
}

@media (max-width:767px) {
    .hero_single .wrapper h3 {
        font-size: 21px;
        font-size: 1.3125rem
    }
}

@media (max-width:575px) {
    .hero_single .wrapper h3 {
        font-size: 23px;
        font-size: 1.4375rem
    }
}

.hero_single .wrapper p {
    font-weight: 300;
    margin: 10px 0 0;
    padding: 0 20%;
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.4
}

.hero_single .wrapper p strong {
    font-weight: 600
}

@media (max-width:767px) {
    .hero_single .wrapper p {
        padding: 0;
        font-size: 18px;
        font-size: 1.125rem
    }
}

.hero_single.short {
    height: 600px
}

.hero_single.version_2 {
    height: 620px
}

.hero_single.version_2:before {
    /* background: url(../img/home_section_1.jpg) center center no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.hero_single.version_2 .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, .45)
}

.hero_single.version_2 .wrapper h3 {
    font-size: 42px;
    font-size: 2.625rem
}

@media (max-width:767px) {
    .hero_single.version_2 .wrapper h3 {
        font-size: 26px;
        font-size: 1.625rem;
        margin-top: 60px
    }
}

@media (max-width:575px) {
    .hero_single.version_2 .wrapper h3 {
        font-size: 22px;
        font-size: 1.375rem
    }
}

.hero_single.version_2 .wrapper p {
    font-weight: 400;
    margin: 5px 0 0;
    padding: 0;
    font-size: 21px;
    font-size: 1.3125rem
}

.hero_single.version_2 .wrapper p strong {
    font-weight: 600
}

@media (max-width:767px) {
    .hero_single.version_2 .wrapper p {
        padding: 0;
        font-size: 14px;
        font-size: .875rem
    }
}

#custom-search-input {
    padding: 0;
    width: 600px;
    margin: 20px auto 0;
    position: relative
}

@media (max-width:991px) {
    #custom-search-input {
        width: auto
    }
}

#custom-search-input .search-query {
    width: 100%;
    height: 50px;
    padding-left: 20px;
    border: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    font-size: 16px;
    font-size: 1rem;
    color: #333
}

#custom-search-input .search-query:focus {
    outline: 0
}

@media (max-width:575px) {
    #custom-search-input .search-query {
        padding-left: 15px
    }
}

#custom-search-input input[type=submit] {
    position: absolute;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    right: -1px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    font-size: .875rem;
    top: 0;
    border: 0;
    padding: 0 25px;
    height: 50px;
    cursor: pointer;
    outline: 0;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    background-color: #fc5b62
}

#custom-search-input input[type=submit]:hover {
    background-color: #FFC107;
    color: #222
}

@media (max-width:575px) {
    #custom-search-input input[type=submit] {
        text-indent: -999px;
        /* background: url(../img/search.svg) center center no-repeat #fc5b62 */
    }
}

.custom-search-input-2 {
    background-color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .3);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .3)
}

.custom-search-input-2 input {
    border: 0;
    height: 50px;
    padding-left: 25px;
    border-right: 1px solid #d2d8dd;
    font-weight: 500
}

@media (max-width:991px) {
    .custom-search-input-2 {
        background: 0 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }
    .custom-search-input-2 input {
        border: none
    }
}

.custom-search-input-2 input:focus {
    box-shadow: none;
    border-right: 1px solid #d2d8dd
}

.custom-search-input-2 .nice-select .current {
    font-weight: 500;
    color: #6f787f
}

.custom-search-input-2 .form-group {
    margin: 0
}

.custom-search-input-2 i {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    font-size: 18px;
    font-size: 1.125rem;
    position: absolute;
    background-color: #fff;
    line-height: 50px;
    top: 0;
    right: 1px;
    padding-right: 15px;
    display: block;
    width: 20px;
    box-sizing: content-box;
    height: 50px;
    z-index: 9;
    color: #999
}

@media (max-width:991px) {
    .custom-search-input-2 input:focus {
        border-right: none
    }
    .custom-search-input-2 .form-group {
        margin-bottom: 5px
    }
    .custom-search-input-2 i {
        padding-right: 10px
    }
}

.custom-search-input-2 input[type=submit] {
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    font-size: .875rem;
    border: 0;
    padding: 0 185px;
    height: 50px;
    cursor: pointer;
    outline: 0;
    width: 100%;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    background-color: #fc5b62;
    margin-right: -1px
}

@media (max-width:991px) {
    .custom-search-input-2 input[type=submit] {
        margin: 20px 0 0;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px
    }
}

.custom-search-input-2 input[type=submit]:hover {
    background-color: #FFC107;
    color: #222
}

.custom-search-input-2.inner {
    margin-bottom: 30px;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1)
}

@media (max-width:991px) {
    .custom-search-input-2.inner {
        margin: 0 0 20px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }
}

.custom-search-input-2.inner-2 {
    margin: 0 0 20px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: 0 0
}

.custom-search-input-2.inner-2 .form-group {
    margin-bottom: 10px
}

.custom-search-input-2.inner-2 input {
    border: 1px solid #ededed
}

.custom-search-input-2.inner-2 input[type=submit] {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    margin-top: 10px
}

.custom-search-input-2.inner-2 i {
    padding-right: 10px;
    line-height: 48px;
    height: 48px;
    top: 1px
}

.custom-search-input-2.inner-2 .nice-select {
    border: 1px solid #ededed
}

.panel-dropdown {
    position: relative;
    text-align: left;
    padding: 15px 10px 0 15px
}

@media (max-width:991px) {
    .panel-dropdown {
        background-color: #fff;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px;
        height: 50px
    }
}

.panel-dropdown a {
    color: #727b82;
    font-weight: 500;
    transition: all .3s;
    display: block;
    position: relative
}

.panel-dropdown a:after {
    content: "\33";
    font-size: 24px;
    font-size: 1.5rem;
    color: #999;
    font-weight: 500;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    position: absolute;
    right: 0;
    top: -8px
}

.panel-dropdown.active a:after {
    transform: rotate(180deg)
}

.panel-dropdown .panel-dropdown-content {
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    position: absolute;
    top: 58px;
    left: 0;
    z-index: 99;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 12px 35px 2px rgba(0, 0, 0, .12);
    padding: 15px 15px 0;
    white-space: normal;
    width: 280px
}

.panel-dropdown .panel-dropdown-content:after {
    bottom: 100%;
    left: 15px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: -7px
}

.panel-dropdown .panel-dropdown-content.right {
    left: auto;
    right: 0
}

.panel-dropdown .panel-dropdown-content.right:after {
    left: auto;
    right: 15px
}

.panel-dropdown.active .panel-dropdown-content {
    opacity: 1;
    visibility: visible
}

.qtyButtons {
    display: flex;
    margin: 0 0 13px
}

.qtyButtons input {
    outline: 0;
    font-size: 16px;
    font-size: 1rem;
    text-align: center;
    width: 50px;
    height: 36px!important;
    color: #333;
    line-height: 36px;
    margin: 0!important;
    padding: 0 5px!important;
    box-shadow: none;
    pointer-events: none;
    display: inline-block;
    border: none!important
}

.qtyButtons label {
    font-weight: 500;
    line-height: 36px;
    padding-right: 15px;
    display: block;
    flex: 1;
    color: #727b82
}

.qtyDec,
.qtyInc,
.qtyTotal {
    border-radius: 50%;
    display: inline-block;
    text-align: center
}

.qtyDec,
.qtyInc {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 28px;
    font-size: 1.75rem;
    background-color: #f2f2f2;
    -webkit-text-stroke: 1px #f2f2f2;
    color: #333;
    cursor: pointer
}

.qtyInc:before {
    content: "\4c"
}

.qtyDec:before {
    content: "\4b"
}

.qtyTotal {
    background-color: #66676b;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    line-height: 18px;
    position: relative;
    top: 0;
    left: 2px;
    height: 18px;
    width: 18px
}

.rotate-x {
    animation-duration: .5s;
    animation-name: rotate-x
}

@keyframes rotate-x {
    from {
        transform: rotateY(0)
    }
    to {
        transform: rotateY(360deg)
    }
}

.carousel-list {
    margin-top: 40px
}


@media (max-width:767px) {
    .carousel-list {
        margin-top: 0
    }
}

.carousel-list .item {
    margin: 0 15px
}

.carousel-list .owl-item {
    opacity: .5;
    transform: scale(.85);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(.85, .85);
    transition: all .3s ease-in-out 0s;
    overflow: hidden
}

.carousel-list .owl-item.active.center,
a.box_news figure img {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden
}

a.box_news figure img,
a.box_news:hover h4 {
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out
}

.carousel-list .owl-item.active.center {
    opacity: 1;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: scale(1)
}

.carousel-list .owl-item.active.center .item .title h4,
.carousel-list .owl-item.active.center .item .views {
    opacity: 1
}

.owl-theme .owl-dots {
    margin-top: 10px!important;
    margin-bottom: 25px
}

@media (max-width:767px) {
    .owl-theme .owl-dots {
        margin-top: 0!important;
        margin-bottom: 20px
    }
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #fc5b62!important
}

.owl-theme .owl-dots .owl-dot span {
    width: 13px!important;
    height: 13px!important
}

a.box_news {
    position: relative;
    display: block;
    padding-left: 230px;
    color: #555;
    margin-bottom: 30px;
    min-height: 150px
}

a.box_news figure {
    width: 200px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0
}

@media (max-width:767px) {
    a.box_news {
        min-height: inherit;
        padding-left: 0
    }
    a.box_news figure {
        position: relative;
        width: auto
    }
}

a.box_news figure img {
    width: 250px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -moz-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    -o-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
    transition: all .3s ease-in-out;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden
}

#carousel .owl-item,
#carousel .owl-item.active.center {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden
}

@media (max-width:767px) {
    a.box_news figure img {
        width: 100%;
        max-width: 100%;
        height: auto
    }
}

a.box_news figure figcaption {
    background-color: #FFC107;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    color: #fff;
    width: 60px;
    height: 60px;
    padding: 15px;
    line-height: 1
}

a.box_news figure figcaption strong {
    display: block;
    font-size: 21px;
    font-size: 1.3125rem
}

a.box_news h4 {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 10px
}

@media (max-width:767px) {
    a.box_news h4 {
        margin-bottom: 5px
    }
}

a.box_news:hover img {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}

a.box_news:hover h4 {
    color: #fc5b62;
    transition: all .3s ease-in-out
}

a.box_news ul {
    margin: 0;
    padding: 10px 0 0
}

@media (max-width:767px) {
    a.box_news ul {
        padding: 0
    }
}

a.box_news ul li {
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    color: #999;
    font-size: 12px;
    font-size: .75rem;
    margin-right: 5px
}

a.box_news ul li:last-child {
    margin-right: 0
}

a.box_news ul li:last-child:after {
    content: '';
    margin: 0
}

a.box_news ul li:after {
    content: '-';
    margin: 0 0 0 10px
}

a.grid_item {
    display: block;
    margin-bottom: 25px
}

a.grid_item .info {
    position: absolute;
    width: 100%;
    z-index: 9;
    display: block;
    padding: 25px 20px 5px;
    color: #fff;
    left: 0;
    bottom: 0;
    background: 0 0;
    background: -webkit-linear-gradient(top, transparent, #000);
    background: linear-gradient(to bottom, transparent, #000);
    box-sizing: border-box
}

a.grid_item .info .cat_star {
    font-size: 9px;
    font-size: .5625rem
}

a.grid_item .info .cat_star i {
    color: #fff
}

a.grid_item .info h3 {
    color: #fff;
    font-size: 21px;
    font-size: 1.3125rem
}

a.grid_item .info p {
    color: #fff;
    margin-bottom: 0;
    font-size: 15px;
    font-size: .9375rem
}

a.grid_item figure {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%
}

@media (max-width:767px) {
    a.grid_item figure {
        height: 350px!important
    }
}

@media (max-width:575px) {
    a.grid_item figure {
        height: 220px!important
    }
}

a.grid_item figure .score {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9
}

a.grid_item figure small {
    position: absolute;
    background-color: #000;
    background-color: rgba(0, 0, 0, .6);
    left: 20px;
    top: 22px;
    text-transform: uppercase;
    color: #ccc;
    font-weight: 600;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 7px 10px 4px;
    line-height: 1;
    z-index: 9
}

.box_grid a.wish_bt,
a.box_feat {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px
}

a.grid_item figure img {
    width: 100%;
    height: auto;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1)
}

a.grid_item:hover figure img {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.search_container {
    padding: 30px 0 15px;
    border-bottom: 1px solid #ededed
}

a.box_feat {
    text-align: center;
    background: #fff;
    padding: 40px 30px 30px;
    display: block;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-box-shadow: 0 3px 0 0 #f0f2f4;
    -moz-box-shadow: 0 3px 0 0 #f0f2f4;
    box-shadow: 0 3px 0 0 #f0f2f4;
    color: #555;
    margin-bottom: 30px;
    border-radius: 3px;
    overflow: hidden
}

a.box_feat:hover {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 3px 0 0 #ebedef;
    -moz-box-shadow: 0 3px 0 0 #ebedef;
    box-shadow: 0 3px 0 0 #ebedef
}

.box_grid,
.box_list {
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1)
}

a.box_feat i {
    font-size: 70px;
    font-size: 4.375rem;
    color: #32a067;
    margin-bottom: 10px
}

a.box_feat h3 {
    font-size: 18px;
    font-size: 1.125rem
}

#carousel {
    margin-top: 40px
}

@media (max-width:767px) {
    a.box_feat {
        padding: 20px 20px 0
    }
    #carousel {
        margin-bottom: 30px
    }
}

#carousel .item .title {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    z-index: 9
}

#carousel .item .title h4 {
    background-color: #0054a6;
    display: inline-block;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    color: #fff;
    padding: 10px 15px;
    margin: 0;
    font-size: 16px;
    font-size: 1rem;
    opacity: 0
}

#carousel .item .title h4 em {
    display: block;
    font-size: 13px;
    font-size: .8125rem;
    font-weight: 300;
    margin-top: 3px
}

#carousel .item a {
    position: relative;
    display: block
}

#carousel .owl-item {
    opacity: .5;
    transform: scale(.85);
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(.85, .85);
    transition: all .3s ease-in-out 0s;
    overflow: hidden
}

#carousel .owl-item.active.center {
    opacity: 1;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: scale(1)
}

#carousel .owl-item.active.center .item .title h4,
#carousel .owl-item.active.center .item .views {
    opacity: 1
}

.filters_listing {
    padding: 10px 0 5px;
    background: #fff;
    border-bottom: 1px solid #ededed;
    z-index: 999999!important;
    position: relative
}

.filters_listing ul {
    margin: 0 15px
}

@media (max-width:991px) {
    .filters_listing ul {
        margin: 0
    }
    .filters_listing ul li:nth-child(2) {
        display: none
    }
}

.filters_listing ul li {
    margin-right: 10px
}

.filters_listing ul li:first-child {
    float: left
}

.filters_listing ul li:nth-child(2) {
    float: right;
    margin-right: 0
}

.filters_listing ul li:nth-child(3) {
    float: right
}

@media (max-width:991px) {
    .filters_listing ul li:nth-child(3) {
        margin-right: 0
    }
}

.score strong {
    background-color: #0054a6;
    color: #fff;
    line-height: 1;
    -webkit-border-radius: 5px 5px 5px 0;
    -moz-border-radius: 5px 5px 5px 0;
    -ms-border-radius: 5px 5px 5px 0;
    border-radius: 5px 5px 5px 0;
    padding: 10px;
    display: inline-block
}

.score span {
    display: inline-block;
    position: relative;
    top: 7px;
    margin-right: 8px;
    font-size: 12px;
    font-size: .75rem;
    text-align: right;
    line-height: 1.1;
    font-weight: 500
}

.score span em {
    display: block;
    font-weight: 400;
    font-size: 11px;
    font-size: .6875rem
}

.wrapper-grid {
    padding: 0 20px
}

.box_grid {
    width: 270px;
    background-color: #fff;
    display: block;
    position: relative;
    margin-bottom: 30px;
    /* border:1px solid #707070; */
    margin: 3px 5px;
}

.box_grid .price {
    display: inline-block;
    font-weight: 500;
    color: #999
}

.box_grid .price strong {
    color: #32a067
}

.box_grid a.wish_bt {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, .6);
    padding: 9px 10px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    border-radius: 3px
}

.box_grid figure small,
.box_list a.wish_bt {
    background-color: #000;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    line-height: 1
}

.box_grid a.wish_bt:after {
    font-family: ElegantIcons;
    content: "\e030";
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease
}

.box_grid a.wish_bt.liked:after {
    content: "\e089";
    color: #fc5b62
}

.box_grid a.wish_bt:hover.liked:after {
    color: #fc5b62
}

.box_grid a.wish_bt:hover:after {
    content: "\e089";
    color: #fff
}

.box_grid figure {
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    height: 210px
}

.box_grid figure small {
    position: absolute;
    background-color: rgba(0, 0, 0, .6);
    left: 20px;
    top: 22px;
    text-transform: uppercase;
    color: #ccc;
    font-weight: 600;
    border-radius: 3px;
    padding: 7px 10px 4px
}

.box_grid figure .read_more {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -12px;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    text-align: center;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    -webkit-transition: all .6s;
    transition: all .6s;
    z-index: 2
}

.box_grid figure a img,
.box_list figure a img {
    -o-transition: all .3s ease-in-out;
    -moz-backface-visibility: hidden
}

.box_grid figure .read_more span {
    background-color: #fcfcfc;
    background-color: rgba(255, 255, 255, .8);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
    display: inline-block;
    color: #222;
    font-size: 12px;
    font-size: .75rem;
    padding: 5px 10px
}

.box_grid figure:hover .read_more {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
}

.box_grid figure a img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -moz-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    -o-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    -moz-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.animated .box_1,
.box_list figure a img {
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden
}

.box_grid figure a:hover img {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}

.box_grid .wrapper {
    padding: 25px
}

.box_grid .wrapper h3 {
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: 0
}

.box_grid ul {
    padding: 20px 15px;
    border-top: 1px solid #ededed
}

.box_grid ul li {
    display: inline-block;
    margin-right: 15px
}

.box_grid ul li .score {
    margin-top: -10px
}

.box_grid ul li:last-child {
    margin-right: 0;
    float: right
}

.cat_star i,
.rating i {
    margin-right: 2px
}

.cat_star i {
    color: #FFC107
}

.rating {
    color: #ccc
}

.rating .voted {
    color: #FFC107
}

.box_list {
    background-color: #fff;
    display: block;
    position: relative;
    margin: 0 15px 30px;
    min-height: 310px;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1)
}

.box_list a.wish_bt {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    background-color: rgba(0, 0, 0, .1);
    padding: 9px 10px;
    display: inline-block;
    color: #555;
    border-radius: 3px
}

.box_list a.wish_bt:after {
    font-family: ElegantIcons;
    content: "\e030";
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease
}

.box_list a.wish_bt.liked:after {
    content: "\e089";
    color: #fc5b62
}

.box_list a.wish_bt:hover.liked:after {
    color: #fc5b62
}

.box_list a.wish_bt:hover:after {
    content: "\e089";
    color: #999
}

.box_list figure {
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    min-height: 310px
}

.box_list figure small {
    position: absolute;
    background-color: #000;
    background-color: rgba(0, 0, 0, .6);
    right: 20px;
    top: 20px;
    text-transform: uppercase;
    color: #ccc;
    font-weight: 600;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 7px 10px 4px;
    line-height: 1;
    z-index: 99
}

@media (max-width:991px) {
    .box_list figure small {
        right: 15px;
        top: 15px
    }
    .box_list figure {
        min-height: 220px;
        max-height: 220px;
        height: 220px
    }
}

.box_list figure .read_more {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -12px;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    text-align: center;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    -webkit-transition: all .6s;
    transition: all .6s;
    z-index: 2
}

.box_list figure a img,
a.boxed_list {
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out
}

.box_list figure .read_more span {
    background-color: #fcfcfc;
    background-color: rgba(255, 255, 255, .8);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
    display: inline-block;
    color: #222;
    font-size: 12px;
    font-size: .75rem;
    padding: 5px 10px
}

.booking .panel-dropdown,
.layout_view {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px
}

.box_list figure:hover .read_more {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
}

.box_list figure a img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -moz-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    -o-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 320px;
    width: auto;
    max-width: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

@media (max-width:991px) {
    .box_list figure a img {
        max-width: inherit;
        height: 460px;
        width: auto
    }
}

@media (max-width:767px) {
    .box_list figure a img {
        height: 350px
    }
}

.box_list figure a:hover img {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}

.box_list .wrapper {
    padding: 30px 30px 20px;
    min-height: 245px
}

@media (max-width:991px) {
    .box_list .wrapper {
        min-height: inherit
    }
}

.box_list .wrapper h3 {
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: 0
}

.box_list .wrapper .price {
    display: inline-block;
    font-weight: 500;
    color: #999
}

.box_list .wrapper .price strong {
    color: #32a067
}

.box_list small {
    text-transform: uppercase;
    color: #999;
    font-weight: 600
}

.box_list ul {
    padding: 20px 15px 20px 30px;
    border-top: 1px solid #ededed;
    margin-bottom: 0
}

.box_list ul li {
    display: inline-block;
    margin-right: 15px
}

.box_list ul li .score {
    margin-top: -10px
}

.box_list ul li:last-child {
    margin-right: 0;
    float: right
}

#list_sidebar .box_list,
#list_sidebar .box_list figure {
    min-height: 330px
}

@media (max-width:991px) {
    #list_sidebar .box_list figure {
        min-height: 200px;
        max-height: 200px;
        height: 200px
    }
}

#list_sidebar .box_list figure a img {
    height: 350px
}

#list_sidebar .box_list .wrapper {
    min-height: 265px
}

@media (max-width:991px) {
    #list_sidebar .box_list figure a img {
        max-width: 100%;
        height: auto;
        width: inherit
    }
    #list_sidebar .box_list .wrapper {
        min-height: inherit
    }
}

.layout_view {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, .08);
    padding: 4px 8px 3px
}

.layout_view a {
    color: rgba(0, 0, 0, .5)
}

.layout_view a.active {
    color: rgba(0, 0, 0, .8)
}

.layout_view a:hover {
    color: #fc5b62
}

.switch-field {
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, .08);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px
}

.switch-field input {
    position: absolute!important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden
}

.switch-field input:checked+label {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: rgba(0, 0, 0, .9)
}

.switch-field label {
    float: left;
    display: inline-block;
    min-width: 70px;
    color: rgba(0, 0, 0, .5);
    font-weight: 600;
    font-size: 12px;
    font-size: .75rem;
    text-align: center;
    text-shadow: none;
    padding: 10px 12px 8px;
    line-height: 1;
    border-right: 1px solid rgba(0, 0, 0, .08);
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    margin: 0
}

.switch-field label:first-of-type {
    border-left: 0
}

.switch-field label:last-of-type {
    border-right: 0
}

.switch-field label:hover {
    cursor: pointer;
    color: #fc5b62
}

#filters_col {
    background-color: #fff;
    padding: 20px 20px 15px;
    margin-bottom: 25px;
    border: 1px solid #ededed
}

#filters_col label {
    color: #333;
    font-weight: 400;
    font-size: 13px;
    font-size: .8125rem
}

a#filters_col_bt {
    display: block;
    color: #333;
    position: relative;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600
}

a#filters_col_bt:before {
    font-family: ElegantIcons;
    content: "\66";
    font-size: 26px;
    font-size: 1.625rem;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1;
    font-weight: 400
}

.filter_type h6 {
    border-top: 1px solid #ededed;
    margin: 15px 0;
    padding: 15px 0 0;
    font-size: 13px;
    font-size: .8125rem
}

.filter_type ul {
    list-style: none;
    padding: 0;
    margin: 0 0 15px
}

.filter_type ul li {
    margin-bottom: 5px
}

.filter_type ul li:last-child {
    margin-bottom: 0
}

a.boxed_list {
    text-align: center;
    padding: 30px;
    border: 1px solid #ededed;
    display: block;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    margin-bottom: 25px
}

a.boxed_list i {
    font-size: 60px;
    font-size: 3.75rem;
    margin-bottom: 15px
}

.secondary_nav ul,
a.boxed_list p {
    margin-bottom: 0
}

a.boxed_list h4 {
    font-size: 18px;
    font-size: 1.125rem;
    color: #333
}

a.boxed_list p {
    color: #777
}

a.boxed_list:hover i {
    color: #32a067
}

.secondary_nav {
    background: #fff;
    border-bottom: 1px solid #ededed;
    padding: 100px 0 15px 0;
}

#review_summary,
.reviews-container .progress-bar {
    background-color: #0054a6
}

.secondary_nav.is_stuck {
    z-index: 99;
    width: 100%!important;
    left: 0
}

.secondary_nav ul li {
    display: inline-block;
    margin-right: 20px;
    font-weight: 500;
    font-size: 16px;
    font-size: 1rem
}

.secondary_nav ul li a {
    color: rgba(0, 0, 0, .5)
}

.secondary_nav ul li a:hover {
    color: #fc5b62
}

.secondary_nav ul li a.active {
    color: rgba(0, 0, 0, .9)
}

.secondary_nav ul li:last-child {
    display: none
}

.intro_title h2,
.intro_title ul li {
    display: inline-block
}

@media (max-width:575px) {
    .switch-field label {
        min-width: inherit
    }
    .secondary_nav ul li:last-child {
        display: inline-block
    }
}

.intro_title ul {
    float: right;
    margin: 10px 0 0
}

.intro_title ul li {
    margin-right: 20px;
    font-weight: 500
}

section#description {
    padding-top: 30px;
}

section#description h2,
section#reviews h2 {
    font-size: 24px;
    font-size: 1.5rem
}

section#description h3,
section#reviews h3 {
    font-size: 21px;
    font-size: 1.3125rem
}

section#description h4,
section#reviews h4 {
    font-size: 18px;
    font-size: 1.125rem
}

section#reviews {
    border-bottom: none;
    margin-bottom: 70px;
}

#review_summary {
    text-align: center;
    color: #fff;
    padding: 20px 10px;
    -webkit-border-radius: 5px 5px 5px 0;
    -moz-border-radius: 5px 5px 5px 0;
    -ms-border-radius: 5px 5px 5px 0;
    border-radius: 5px 5px 5px 0
}

@media (max-width:991px) {
    #review_summary {
        margin-bottom: 15px
    }
}

#review_summary strong {
    font-size: 42px;
    font-size: 1.5rem;
    display: block;
    line-height: 1
}

#review_summary em {
    font-style: normal;
    font-weight: 500;
    display: block
}

.reviews-container .progress {
    margin-bottom: 7px;
    height: 15px;
}

.reviews-container .review-box {
    position: relative;
    margin-bottom: 25px;
    padding-left: 100px;
    min-height: 100px
}

.reviews-container .rev-thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden
}

.reviews-container .rev-thumb img {
    width: 80px;
    height: auto
}

@media (max-width:767px) {
    .reviews-container .review-box {
        padding-left: 0
    }
    .reviews-container .rev-thumb {
        position: static;
        margin-bottom: 10px
    }
}

.reviews-container .rev-content {
    position: relative;
    padding: 25px 25px 0;
    border: 1px solid #ededed;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px
}

.reviews-container .rev-info {
    font-size: 12px;
    font-size: .75rem;
    font-style: italic;
    color: #777;
    margin-bottom: 10px
}

.purchase-box {
    padding-top: 30px;
    margin-bottom: 152px;
}
.box_detail {
    padding: 25px 25px 15px;
    border: 1px solid #ededed;
    background-color: #f8f8f8;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 30px
}

.box_detail .price {
    line-height: 1;
    border-bottom: 1px solid #ededed;
    margin: 0 -25px 25px;
    padding: 0 25px 15px
}

.box_detail .price>span {
    font-size: 31px;
    font-size: 1.9375rem;
    font-weight: 600
}

.box_detail .price>span>small {
    font-size: 11px;
    font-size: .6875rem;
    font-weight: 500
}

.box_detail .price .score {
    float: right;
    margin-top: -5px
}

.box_detail h3 {
    font-size: 20px;
    font-size: 1.25rem;
    margin: 25px 0 10px
}

.box_detail ul {
    margin-bottom: 0
}

.box_detail ul li,
.card {
    margin-bottom: 5px
}

.box_detail ul li i {
    margin-right: 8px
}

.box_detail figure {
    position: relative;
    background-color: #000
}

.box_detail figure img {
    opacity: .8
}

.box_detail figure span {
    position: absolute;
    display: block;
    left: 0;
    bottom: 10px;
    text-align: center;
    width: 100%;
    color: #fff;
    font-weight: 600
}

.box_detail figure a i {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6);
    display: block;
    width: 60px;
    height: 60px;
    color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    line-height: 60px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    font-size: 42px;
    font-size: 2.625rem;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    z-index: 9
}

.booking .custom-select-form select,
.booking select {
    display: none
}

.box_detail figure:hover i {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3)
}

.booking input {
    font-weight: 500;
    height: 45px
}

.booking input:focus {
    box-shadow: none;
    border: 1px solid #d2d8dd
}

.booking .panel-dropdown {
    background-color: #fff;
    font-size: 14px;
    font-size: .875rem;
    border-radius: 3px;
    border: 1px solid #d2d8dd;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 0 10px
}

@media (max-width:991px) {
    .booking .panel-dropdown {
        height: inherit
    }
}

.booking .panel-dropdown .panel-dropdown-content {
    top: 50px
}

.booking .nice-select .current {
    font-weight: 500;
    color: #6f787f
}

.booking .custom-select-form .nice-select.open,
.booking .custom-select-form .nice-select:active,
.booking .custom-select-form .nice-select:focus {
    border-color: #d2d8dd;
    outline: 0;
    box-shadow: none
}

.booking .form-group {
    margin: 0 0 10px
}

.booking .form-group i {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    font-size: 18px;
    font-size: 1.125rem;
    position: absolute;
    background-color: #fff;
    height: 43px;
    top: 1px;
    right: 1px;
    padding-right: 10px;
    display: block;
    width: 20px;
    box-sizing: content-box;
    line-height: 43px;
    z-index: 9;
    color: #999
}

.booking .form-group i.icon_lock_alt {
    top: 36px
}

ul.cbp_tmtimeline {
    margin: 100px 0;
    padding: 0;
    list-style: none;
    position: relative;
    margin-bottom: 5px;
}

.cbp_tmtimeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #ebeef3;
    left: 18.5%;
    margin-left: -2px
}

.cbp_tmtimeline>li {
    position: relative
}

.cbp_tmtimeline>li .cbp_tmtime {
    display: block;
    width: 23%;
    padding-right: 80px;
    position: absolute
}

.cbp_tmtimeline>li .cbp_tmtime span {
    display: block;
    text-align: right
}

.cbp_tmtimeline>li .cbp_tmtime span:first-child {
    font-size: .9em;
    color: #888;
    margin-bottom: 0
}

.cbp_tmtimeline>li .cbp_tmtime span:last-child {
    font-size: 18px;
    font-weight: 500
}

img.thumb_visit {
    width: 70px;
    height: 70px;
    border: 2px solid #fff;
    margin-top: -5px;
    float: right;
    margin-left: 15px
}

@media (max-width:767px) {
    img.thumb_visit {
        display: none
    }
}

.cbp_tmtimeline>li .cbp_tmlabel {
    margin: 0 0 15px 25%;
    background: #eef3f7;
    padding: 20px;
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}

.cbp_tmtimeline>li .cbp_tmlabel p {
    margin-bottom: 0;
    font-size: 13px;
    font-size: .8125rem
}

.cbp_tmtimeline>li .cbp_tmlabel h4 {
    margin: 0 0 5px;
    padding: 0;
    font-size: 16px;
    font-size: 1rem
}

.cbp_tmtimeline>li .cbp_tmlabel:after {
    right: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #eef3f7;
    border-width: 10px;
    top: 15px
}

.cbp_tmtimeline>li .cbp_tmicon {
    width: 48px;
    height: 48px;
    font-weight: 500;
    background-color: #fff;
    text-transform: none;
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 50px;
    position: absolute;
    color: #f93;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #999;
    text-align: center;
    left: 18.5%;
    top: 0;
    margin: 0 0 0 -25px
}

@media screen and (max-width:65.375em) {
    .cbp_tmtimeline>li .cbp_tmtime span:last-child {
        font-size: 1.5em
    }
}

@media screen and (max-width:47.2em) {
    .cbp_tmtimeline>li .cbp_tmlabel:after,
    .cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel:after {
        border-right-color: transparent;
        border-bottom-color: #eef3f7
    }
    .cbp_tmtimeline:before {
        display: none
    }
    .cbp_tmtimeline>li .cbp_tmtime {
        width: 100%;
        position: relative;
        padding: 0 0 20px
    }
    .cbp_tmtimeline>li .cbp_tmtime span {
        text-align: left
    }
    .cbp_tmtimeline>li .cbp_tmlabel {
        margin: 0 0 30px;
        padding: 1em
    }
    .cbp_tmtimeline>li .cbp_tmlabel:after {
        right: auto;
        left: 20px;
        top: -20px
    }
    .cbp_tmtimeline>li .cbp_tmicon {
        position: relative;
        float: right;
        left: auto;
        margin: -65px 5px 0 0
    }
}

ul.hotel_facilities {
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2
}

ul.hotel_facilities li {
    margin-bottom: 10px;
    display: inline-block;
    width: 100%
}

ul.hotel_facilities li img {
    margin-right: 10px
}

.room_type {
    padding: 30px 30px 10px
}

.room_type.first {
    padding: 0 30px 15px
}

.room_type.gray {
    background-color: #f8f8f8
}

.room_type.last {
    padding: 30px 30px 0
}

@media (max-width:767px) {
    .room_type h4 {
        margin-top: 25px
    }
}

#login_bg,
#register_bg {
    /* background: url(../img/hero_in_bg_3.jpg) center center no-repeat fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    width: 100%
}

#register_bg {
    /* background: url(../img/hero_in_bg_2.jpg) center center no-repeat fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

#login figure,
#register figure {
    text-align: center;
    border-bottom: 1px solid #ededed;
    margin: -45px -60px 25px;
    padding: 20px 60px 25px
}

#login aside,
#register aside {
    width: 430px;
    padding: 45px 60px 60px;
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
    background-color: #fff;
    min-height: 100vh
}

@media (max-width:767px) {
    #login figure,
    #register figure {
        margin: -30px -30px 20px;
        padding: 15px 60px 20px
    }
    #login aside,
    #register aside {
        width: 100%;
        padding: 30px
    }
}

#login aside form,
#register aside form {
    margin-bottom: 60px;
    display: block
}

#login aside form .form-group input,
#register aside form .form-group input {
    padding-left: 40px
}

#login aside form .form-group i,
#register aside form .form-group i {
    font-size: 21px;
    font-size: 1.3125rem;
    position: absolute;
    left: 12px;
    top: 34px;
    color: #ccc;
    width: 25px;
    height: 25px;
    display: block;
    font-weight: 400!important
}

#login aside .copy,
#register aside .copy {
    text-align: center;
    position: absolute;
    height: 30px;
    left: 0;
    bottom: 30px;
    width: 100%;
    color: #999;
    font-weight: 500
}

.access_social {
    margin-top: 45px
}

@media (max-width:767px) {
    .access_social {
        margin-top: 30px
    }
}

a.social_bt.linkedin {
    background-color: #0077B5
}

a.social_bt.linkedin:before {
    content: "\e09d"
}

#pass-info {
    width: 100%;
    margin-bottom: 15px;
    color: #555;
    text-align: center;
    font-size: 12px;
    font-size: .75rem;
    padding: 5px 3px 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px
}

#pass-info.weakpass {
    border: 1px solid #FF9191;
    background: #FFC7C7;
    color: #94546E
}

#pass-info.stillweakpass {
    border: 1px solid #FBB;
    background: #FDD;
    color: #945870
}

#pass-info.goodpass {
    border: 1px solid #C4EEC8;
    background: #E4FFE4;
    color: #51926E
}

#pass-info.strongpass {
    border: 1px solid #6ED66E;
    background: #79F079;
    color: #348F34
}

#pass-info.vrystrongpass {
    border: 1px solid #379137;
    background: #48B448;
    color: #CDFFCD
}

.container_check {
    display: block;
    position: relative;
    padding-top: 5px;
    font-size: 13px;
    font-size: .8125rem;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px
}

.container_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.container_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #d2d8dd;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.container_check:hover input~.checkmark {
    background-color: #ededed;
    border: 1px solid transparent
}

.container_check input:checked~.checkmark {
    background-color: #0072bc;
    border: 1px solid transparent
}

.container_check .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.container_check input:checked~.checkmark:after,
.infoBox>img {
    display: block
}

.map {
    width: 100%;
    height: 500px
}

.map.map_single {
    height: 285px
}

.infoBox {
    -webkit-animation: fadeIn .9s;
    animation: fadeIn .9s;
    padding-right: 50px
}

.infoBox>img {
    position: absolute!important;
    right: 60px!important;
    top: 10px!important;
    z-index: 9999;
    width: 20px;
    height: 20px;
    cursor: pointer
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.marker_info {
    width: 240px;
    height: 270px;
    border-radius: 5px;
    text-align: left;
    background: #fff;
    position: relative;
    z-index: 999;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .12)
}

.marker_info img {
    display: block;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0
}

.marker_info h3 {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.1;
    font-weight: 500;
    margin-bottom: 3px;
    color: #444
}

.marker_info em {
    display: inline-block;
    font-size: 12px;
    font-size: .75rem;
    color: #999
}

.marker_info span {
    display: block;
    padding: 20px 20px 0;
    font-size: 13px;
    font-size: .8125rem;
    line-height: 1.2;
    color: #fff;
    position: relative
}

.marker_info span strong {
    display: block;
    font-weight: 500
}

.marker_info:after {
    right: 100%;
    top: 56%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent #fff transparent transparent;
    border-width: 12px;
    margin-top: -12px
}

a.btn_infobox_detail {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 20px;
    height: 20px
}

.btn_infobox_get_directions:before,
a.btn_infobox_detail:before,
a.btn_infobox_phone:before {
    font-style: normal;
    font-weight: 400;
    font-family: ElegantIcons;
    font-size: 20px;
    font-size: 1.25rem;
    cursor: pointer
}

a.btn_infobox_detail:before {
    content: "\70";
    color: #ccc
}

a.btn_infobox_detail:before:hover {
    color: #fc5b62
}

.btn_infobox_get_directions,
a.btn_infobox_phone {
    border: none;
    display: inline-block;
    font-weight: 500;
    color: #fc5b62;
    background: 0 0;
    cursor: pointer;
    font-size: 13px;
    font-size: .8125rem;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    outline: 0
}

.btn_infobox_get_directions:focus,
a.btn_infobox_phone:focus {
    outline: 0
}

.btn_infobox_get_directions:hover,
a.btn_infobox_phone:hover {
    color: #333
}

.btn_infobox_get_directions:before {
    font-size: 14px;
    font-size: .875rem;
    content: "\e080";
    position: absolute;
    left: 0;
    top: 0
}

a.btn_infobox_phone:before {
    font-size: 14px;
    content: "\e090";
    position: absolute;
    left: 0;
    top: -2px
}

span.infobox_rate {
    display: inline-block;
    margin: -44px 0 0 -20px;
    float: left;
    background-color: #0054a6;
    padding: 5px 8px;
    font-weight: 500;
    text-align: left;
    font-size: 12px;
    font-size: .75rem
}

.cluster img {
    display: none
}

.cluster-visible {
    text-align: center;
    font-size: 15px!important;
    color: #fff!important;
    font-weight: 500;
    border-radius: 50%;
    width: 36px!important;
    height: 36px!important;
    line-height: 36px!important;
    background-color: #fc5b62
}

.cluster-visible:before {
    border: 7px solid #fc5b62;
    opacity: .2;
    box-shadow: inset 0 0 0 4px #fc5b62;
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: cluster-animation 2.5s infinite
}

@keyframes cluster-animation {
    0%,
    100% {
        transform: scale(1.3) rotate(0)
    }
    50% {
        transform: scale(1.5) rotate(90deg)
    }
}

.map_contact {
    width: 100%;
    height: 465px
}

@media (max-width:991px) {
    .map_contact {
        height: 350px;
        margin-bottom: 45px
    }
}

.contact_info {
    background-color: #121921;
    color: #fff;
    padding: 30px 0;
    text-align: center
}

.contact_info ul {
    margin: 0;
    padding: 0
}

.contact_info ul li {
    float: left;
    width: 33.33%;
    position: relative;
    padding: 0 20px
}

.contact_info ul li span {
    opacity: .7
}

.contact_info ul li h4 {
    font-size: 18px;
    font-size: 1.125rem;
    color: #fc5b62
}

.contact_info ul li i {
    color: #fff;
    font-size: 46px;
    font-size: 2.875rem;
    margin-bottom: 10px
}

.error_message {
    font-weight: 500;
    color: #e3464c
}

#error_page {
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background: url(../img/pattern_1.svg) center bottom repeat-x #0054a6; */
    color: #fff
}

#error_page h2 {
    margin-top: 30px;
    font-size: 140px;
    font-size: 8.75rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1
}

#error_page h2 i {
    color: #FFC107
}

@media (max-width:767px) {
    .contact_info ul li {
        width: 100%;
        float: none;
        margin-bottom: 35px;
        padding: 0
    }
    .contact_info ul li:last-child {
        margin-bottom: 5px
    }
    #error_page {
        background-size: auto 300px
    }
    #error_page h2 {
        font-size: 72px;
        font-size: 4.5rem
    }
}

#error_page p {
    font-size: 21px;
    font-size: 1.3125rem
}

@media (max-width:767px) {
    #error_page p {
        font-size: 18px;
        font-size: 1.125rem
    }
    #error_page {
        padding: 0;
        height: 500px
    }
}

.search_bar_error {
    position: relative;
    margin-bottom: 60px
}

.search_bar_error input[type=text] {
    border: 0;
    height: 50px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding-left: 15px;
    -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 50px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .15)
}

.search_bar_error input[type=submit] {
    position: absolute;
    right: -1px;
    color: #fff;
    font-weight: 600;
    top: 0;
    border: 0;
    padding: 0 25px;
    height: 50px;
    cursor: pointer;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    background-color: #fc5b62;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.bs-wizard>.bs-wizard-step>.bs-wizard-dot,
.thumb_cart {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%
}

.search_bar_error input[type=submit]:hover {
    background-color: #FFC107;
    color: #333
}

/* .cart_section {
    background: url(../img/pattern_1.svg) center bottom repeat-x #0054a6 
} */

.cart_section.last {
    height: 600px
}

@media (max-width:767px) {
    .cart_section {
        background-size: auto 300px
    }
    .cart_section.last {
        height: 500px
    }
}

.bs-wizard {
    width: 100%;
    margin: 50px auto 0
}

.bs-wizard>.bs-wizard-step {
    padding: 0;
    position: relative;
    width: 33.33%;
    float: left
}

.bs-wizard>.bs-wizard-step.complete>.progress>.progress-bar {
    width: 100%
}

.bs-wizard>.bs-wizard-step.active>.progress>.progress-bar {
    width: 50%
}

.bs-wizard>.bs-wizard-step:first-child.active>.progress>.progress-bar {
    width: 0%
}

.bs-wizard>.bs-wizard-step:last-child.active>.progress>.progress-bar {
    width: 100%
}

.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot {
    background-color: #0054a6
}

.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot:after {
    opacity: 0
}

.bs-wizard>.bs-wizard-step:first-child>.progress {
    left: 50%;
    width: 50%
}

.bs-wizard>.bs-wizard-step:last-child>.progress {
    width: 50%
}

.bs-wizard>.bs-wizard-step .bs-wizard-stepnum {
    font-size: 14px;
    font-size: .875rem;
    font-weight: 600;
    text-transform: uppercase
}

.bs-wizard>.bs-wizard-step>.bs-wizard-dot {
    position: absolute;
    width: 50px;
    height: 50px;
    display: block;
    top: 36px;
    left: 50%;
    margin-top: -5px;
    margin-left: -25px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #0054a6
}

.bs-wizard>.bs-wizard-step>.bs-wizard-dot:after {
    content: '\e903';
    font-family: Fontello;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    position: absolute;
    top: -2px;
    left: 9px;
    font-size: 32px;
    font-size: 2rem;
    color: #FFC107
}

ul#cat_nav li a:after,
ul.list_3 li:before {
    font-family: fontello
}

.bs-wizard>.bs-wizard-step>.progress {
    position: relative;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    height: 2px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 34px 0
}

.bs-wizard>.bs-wizard-step>.progress>.progress-bar {
    width: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #fff
}

.bs-wizard>.bs-wizard-step.disabled a.bs-wizard-dot {
    pointer-events: none
}

.table.cart-list {
    margin-bottom: 0
}

.table.cart-list th,
.table.options_cart th {
    background-color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    padding-top: 0
}

.table.cart-list td strong {
    font-weight: 500
}

.table.cart-list.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .025)
}

@media (max-width:991px) {
    .table.cart-list,
    .table.cart-list tbody,
    .table.cart-list td,
    .table.cart-list th,
    .table.cart-list thead,
    .table.cart-list tr {
        display: block;
        border: 0
    }
    .table.cart-list thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px
    }
    .table.cart-list tr {
        border: 1px solid #eee
    }
    .table.cart-list td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%
    }
    .table.cart-list td:before {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap
    }
    .table.cart-list td:nth-of-type(1):before,
    .table.cart-list td:nth-of-type(2):before,
    .table.cart-list td:nth-of-type(3):before,
    .table.cart-list td:nth-of-type(4):before {
        font-weight: 600;
        color: #111
    }
    .table.cart-list td:nth-of-type(1):before {
        content: "Item"
    }
    .table.cart-list td:nth-of-type(2):before {
        content: "Discount"
    }
    .table.cart-list td:nth-of-type(3):before {
        content: "Total"
    }
    .table.cart-list td:nth-of-type(4):before {
        content: "Actions"
    }
    td.options {
        text-align: left!important
    }
    .thumb_cart {
        display: none
    }
}

#total_cart,
ul#cat_nav li,
ul.cart_details {
    border-bottom: 1px solid #ededed
}

#confirm,
.form_title h3 strong,
.grid ul,
a.box_topic,
ul.share-buttons {
    text-align: center
}

.thumb_cart {
    border-radius: 50%;
    overflow: hidden;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    float: left
}

.thumb_cart img {
    width: 60px;
    height: auto
}

.item_cart {
    display: inline-block;
    margin: 20px 0 0 15px;
    font-weight: 500
}

@media (max-width:991px) {
    .item_cart {
        margin: 5px 0 0
    }
}

#total_cart {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 -25px 20px;
    padding: 0 25px 15px;
    line-height: 1
}

#total_cart span {
    font-weight: 600
}

.box_cart label,
ul.cart_details li {
    font-weight: 500
}

ul.cart_details {
    margin: 0 0 25px;
    padding: 0 0 15px
}

ul.cart_details li span {
    float: right
}

.form_title {
    position: relative;
    padding-left: 55px;
    margin-bottom: 0
}

.form_title h3 {
    margin: 0;
    padding: 0;
    font-size: 21px;
    font-size: 1.3125rem
}

.form_title h3 strong {
    background-color: #fc5b62;
    width: 40px;
    height: 40px;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 42px;
    position: absolute;
    left: 0;
    top: -5px
}

.form-control,
.message {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px
}

.form_title p {
    color: #999;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-size: .75rem;
    line-height: 14px
}

.cards-payment {
    margin-top: 28px
}

.box_cart .step {
    padding: 15px 55px 0;
    margin: 0
}

@media (max-width:991px) {
    .cards-payment {
        display: none
    }
    .box_cart .step {
        padding: 15px 0 0
    }
    .cart-options .fix_mobile {
        float: left!important
    }
}

.message {
    background-color: #f8f8f8;
    padding: 15px;
    margin-bottom: 30px;
    border-radius: 3px
}

.message p {
    margin: 0
}

#confirm {
    font-size: 16px;
    font-size: 1rem;
    margin-top: 20px
}

#confirm h4 {
    color: #fff
}

#confirm p {
    font-size: 16px!important;
    font-size: 16px!important;
    margin-bottom: 0
}

.box_cart {
    margin-bottom: 25px
}

.box_cart hr {
    margin: 30px -25px
}

.box_cart .btn_1.outline,
.box_cart a.btn_1.outline {
    border: 2px solid #999;
    color: #999;
    padding: 12px 40px
}

.box_cart .btn_1.outline:hover,
.box_cart a.btn_1.outline:hover {
    color: #fff;
    border: 2px solid #0054a6
}

.cart-options {
    position: relative;
    padding: 25px 0 0;
    border-top: 1px solid #ededed
}

.apply-coupon .form-group {
    position: relative;
    float: left;
    margin-right: 10px
}

.apply-coupon .form-control {
    padding: 10px 12px
}

.grid ul {
    margin: 0 0 25px;
    padding: 0;
    width: 100%
}

.grid ul li {
    display: inline-block;
    padding: 0;
    margin: -3px;
    min-height: 100%;
    width: 25%;
    background-color: #000;
    list-style: none
}

@media (max-width:991px) {
    .grid ul li {
        width: 50%
    }
    .box_style_cat {
        display: none
    }
}

.grid ul li figcaption,
.grid ul li figure img {
    width: 100%;
    height: 100%;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out
}

@media (max-width:767px) {
    .grid ul li {
        width: 100%;
        min-height: 100%;
        float: none
    }
}

.grid ul li figure {
    position: relative;
    overflow: hidden;
    margin: -3px
}

.grid ul li figure img {
    transition: all .3s ease-in-out
}

.grid ul li figure:focus img,
.grid ul li figure:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1)
}

.grid ul li figure:focus figcaption,
.grid ul li figure:hover figcaption {
    opacity: 1
}

.grid ul li figure:focus figcaption .caption-content,
.grid ul li figure:hover figcaption .caption-content {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.grid ul li figcaption {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15% 0;
    background-color: #000;
    background-color: rgba(0, 0, 0, .6);
    text-align: center;
    font-size: 14px;
    font-size: .875rem;
    opacity: 0;
    transition: all .3s ease-in-out
}

.box_style_cat,
a.box_topic {
    background: #fff;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05)
}

.grid ul li figcaption .caption-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -100px;
    width: 200px;
    -webkit-transform: translate(0, 15px);
    -moz-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    -o-transform: translate(0, 15px);
    transform: translate(0, 15px);
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.grid ul li figcaption a {
    color: #fff
}

.grid ul li figcaption a:focus,
.grid ul li figcaption a:hover {
    color: #fc5b62
}

.grid ul li figcaption i {
    font-size: 30px;
    font-size: 1.875rem
}

.grid ul li figcaption p {
    margin-bottom: 0;
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 400
}

.mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    transition: all .2s ease-in-out;
    transform: scale(.8)
}

.mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: all .3s ease-out
}

.block-reveal img,
a.box_topic {
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1)
}

.mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: .8
}

.box_1,
.mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(.8);
    opacity: 0
}

.box_style_cat {
    border: 1px solid #ededed;
    margin-bottom: 25px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05)
}

ul#cat_nav {
    list-style: none;
    margin: 0;
    padding: 0
}

ul#cat_nav li i {
    font-size: 16px;
    font-size: 1rem;
    margin: 2px 10px 0 0;
    float: left
}

ul#cat_nav li:last-child {
    border-bottom: 0;
    padding-bottom: 0
}

ul#cat_nav li a {
    position: relative;
    color: #555;
    display: block;
    padding: 15px 10px
}

ul#cat_nav li a:after {
    content: "\e89b";
    position: absolute;
    right: 15px;
    top: 15px
}

ul#cat_nav li a span {
    font-size: 11px;
    font-size: .6875rem;
    color: #999
}

ul#cat_nav li a#active,
ul#cat_nav li a.active,
ul#cat_nav li a:hover {
    color: #fc5b62
}

.hero_in form {
    animation-delay: 1.3s;
    -webkit-animation-delay: 1.3s;
    -moz-animation-delay: 1.3s
}

a.box_topic {
    padding: 40px 30px 15px;
    display: block;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
    color: #555;
    margin-bottom: 30px;
    overflow: hidden
}

@media (max-width:767px) {
    a.box_topic {
        padding: 20px 20px 0
    }
}

a.box_topic:hover {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px)
}

a.box_topic i {
    font-size: 60px;
    font-size: 3.75rem;
    color: #32a067;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    background-color: #2c8e5b;
    background-color: rgba(50, 160, 103, .05);
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin-bottom: 15px
}
.event-content {
    margin-bottom: 120px
}
.bg_color_1,
.nice-select,
.nice-select .list {
    background-color: #fff
}

a.box_topic h3 {
    font-size: 18px;
    font-size: 1.125rem
}

.list_articles ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.list_articles ul li {
    float: left;
    width: 45%;
    margin-right: 45px;
    padding: 15px 0;
    border-bottom: 1px solid #ededed
}

.list_articles ul li a {
    color: #555;
    display: block
}

.list_articles ul li a:hover {
    color: #fc5b62;
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px)
}

.box_1,
.box_1 h3 {
    color: #fff
}

.list_articles ul li i {
    margin-right: 10px;
    font-size: 16px;
    font-size: 1rem;
    position: relative;
    top: 2px
}

@media (max-width:991px) {
    .list_articles ul li {
        float: none;
        width: 100%;
        margin-right: 0
    }
}

ul.list_3 {
    list-style: none;
    margin: 0;
    padding: 0
}

ul.list_3 li {
    margin-bottom: 0;
    position: relative;
    padding-left: 20px
}

ul.list_3 li strong {
    font-weight: 500
}

ul.list_3 li:before {
    content: "\ea3e";
    position: absolute;
    left: 0;
    top: 0
}

.box_faq,
.form-group {
    position: relative
}

.box_general {
    border: 1px solid #ededed;
    padding: 25px 25px 5px;
    margin-bottom: 25px;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05)
}

.form-control {
    padding: 10px;
    font-size: 14px;
    font-size: .875rem;
    border-radius: 3px;
    border: 1px solid #d2d8dd
}

.box_1 {
    background-color: #121921;
    padding: 45px
}

.box_1 p {
    opacity: .8
}

.animated .box_1 {
    animation: color .3s ease-in-out;
    animation-delay: .3s;
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden
}

.call_section {
    /* background: url(../img/bg_call_section.jpg) center center no-repeat fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 400px;
    padding: 10% 0
}

.box_faq {
    padding-left: 50px
}

.box_faq h4 {
    font-size: 18px;
    font-size: 1.125rem
}

.box_faq i {
    font-size: 36px;
    font-size: 2.25rem;
    position: absolute;
    left: 0;
    top: 0;
    color: #999
}

.custom-select-form .nice-select {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #d2d8dd;
    height: 45px;
    line-height: 42px
}

.custom-select-form .nice-select:hover {
    border-color: #d2d8dd
}

.custom-select-form .nice-select.open,
.custom-select-form .nice-select:active,
.custom-select-form .nice-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-select-form select {
    display: none
}

ul.share-buttons {
    padding: 0;
    list-style: none;
    margin: 0 0 25px
}

ul.share-buttons li {
    display: inline-block;
    margin: 0 5px 5px
}

ul.share-buttons li a {
    background: #fff;
    border: 1px solid #666;
    border-radius: 50px;
    font-size: 13px;
    padding: 7px 20px;
    transition: .3s;
    display: inline-block;
    line-height: 17px;
    font-weight: 500
}

.nice-select,
.nice-select .option {
    cursor: pointer;
    font-weight: 400;
    outline: 0
}

ul.share-buttons li a:hover {
    color: #fff
}

ul.share-buttons li a.fb-share {
    border-color: #3b5998;
    color: #3b5998
}

ul.share-buttons li a.fb-share:hover {
    background: #3b5998;
    color: #fff
}

ul.share-buttons li a.gplus-share {
    border-color: #dd4b39;
    color: #dd4b39
}

ul.share-buttons li a.gplus-share:hover {
    background: #dd4b39;
    color: #fff
}

ul.share-buttons li a.twitter-share {
    border-color: #1da1f2;
    color: #1da1f2
}

ul.share-buttons li a.twitter-share:hover {
    background: #1da1f2;
    color: #fff
}

ul.share-buttons li i {
    font-size: 16px;
    font-size: 1rem;
    position: relative;
    right: 3px;
    top: 2px
}

.nice-select {
    -webkit-tap-highlight-color: transparent;
    border-radius: 3px;
    border: none;
    box-sizing: border-box;
    clear: both;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    height: 50px;
    line-height: 48px;
    padding-left: 15px;
    padding-right: 27px;
    position: relative;
    text-align: left!important;
    transition: all .2s ease-in-out;
    user-select: none;
    white-space: nowrap;
    width: auto;
    color: #555;
    padding-top: 2px
}

.nice-select.right,
.switchery {
    float: right
}

.nice-select:hover {
    border-color: #8c8c8c
}

.nice-select.open,
.nice-select:active,
.nice-select:focus {
    border-color: #999
}

.nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 8px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all .15s ease-in-out;
    width: 8px
}

.nice-select.open:after {
    transform: rotate(-135deg)
}

.nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0)
}

.nice-select.disabled {
    border-color: #9e9e9e;
    color: #999;
    pointer-events: none
}

.nice-select.disabled:after {
    border-color: #ccc
}

.nice-select.wide {
    width: 100%
}

.nice-select.wide .list {
    left: 0!important;
    right: 0!important
}

.nice-select.right .list {
    left: auto;
    right: 0
}

.nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px
}

.nice-select.small:after {
    height: 4px;
    width: 4px
}

.nice-select.small .option {
    line-height: 34px;
    min-height: 34px
}

.nice-select .list {
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, .11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(-25px);
    transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 9;
    height: 18vh;
    overflow: auto
}

.nice-select .list:hover .option:not(:hover) {
    background-color: transparent!important
}

.nice-select .list::-webkit-scrollbar {
    width: 14px;
    height: 18px
}

.nice-select .list::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, .15);
    -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, .05), inset 1px 1px 0 rgba(0, 0, 0, .05)
}

.nice-select .list::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none
}

.nice-select .list::-webkit-scrollbar-corner {
    background-color: transparent
}

.nice-select .option {
    line-height: 38px;
    list-style: none;
    min-height: 38px;
    padding-left: 15px;
    padding-right: 26px;
    text-align: left;
    transition: all .2s
}

#cookie-bar,
#toTop,
.divider,
a.social_bt {
    text-align: center
}

.block-reveal img,
a.social_bt {
    -moz-transition: all .3s ease-in-out
}

.nice-select .option.focus,
.nice-select .option.selected.focus,
.nice-select .option:hover {
    background-color: #f6f6f6
}

.nice-select .option.selected {
    font-weight: 500
}

.nice-select .option.disabled {
    background-color: transparent;
    color: #999;
    cursor: default
}

#toTop,
.colors li a,
.my-toggle,
.switchery,
a.social_bt {
    cursor: pointer
}

.no-csspointerevents .nice-select .list {
    display: none
}

#toTop:after,
.no-csspointerevents .nice-select.open .list {
    display: block
}

#toTop {
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 9999;
    width: 46px;
    height: 46px;
    background-color: rgba(0, 0, 0, .6);
    opacity: 1;
    border-radius: 50%;
    font-size: 21px;
    color: #fff
}

#toTop:after {
    content: "\e899";
    font-family: fontello;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%)
}

#sign-in-dialog .mfp-close:before,
ul.bullets li:before,
ul.list_ok li:before {
    font-family: ElegantIcons
}

#toTop:hover {
    background-color: #fc5b62
}

.accordion_2 .card {
    border: 0;
    margin-bottom: 5px;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05)
}

.accordion_2 .card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #ededed;
    padding: 20px
}

.accordion_2 .card-body {
    padding-bottom: 0
}

.accordion_2 .card-header h5 {
    font-size: 16px;
    font-size: 1rem
}

.accordion_2 .card-header h5 a {
    border: 0;
    display: block;
    color: #999
}

.accordion_2 .card-header h5 a i.indicator {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    float: right;
    color: #fc5b62
}

.card {
    border-radius: 0;
    border: 0
}

ul.list_ok {
    list-style: none;
    margin: 25px 0 30px;
    padding: 0
}

ul.list_ok li {
    position: relative;
    padding-left: 45px;
    margin-bottom: 20px
}

ul.list_ok li:before {
    content: "\52";
    position: absolute;
    left: 0;
    top: -10px;
    font-size: 32px;
    font-size: 2rem;
    color: #8dc63f
}

ul.bullets {
    line-height: 1.8;
    margin: 0;
    padding: 0
}

.add_bottom_15,
a.social_bt {
    margin-bottom: 15px
}

ul.bullets li {
    position: relative;
    padding-left: 20px
}

ul.bullets li:before {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-size: .875rem;
    content: "\5c";
    color: #ccc;
    position: absolute;
    left: 0;
    top: 0
}

.add_bottom_30 {
    margin-bottom: 30px
}

.add_bottom_45 {
    margin-bottom: 45px
}

.add_bottom_60 {
    margin-bottom: 60px
}

.add_bottom_75 {
    margin-bottom: 75px
}

.add_top_10 {
    margin-top: 10px
}

.add_top_15 {
    margin-top: 15px
}

.add_top_20 {
    margin-top: 20px
}

.add_top_30 {
    margin-top: 30px
}

.add_top_60 {
    margin-top: 60px
}

.more_padding_left {
    padding-left: 40px
}

.nomargin_top {
    margin-top: 0
}

.nomargin,
.nopadding {
    margin: 0!important
}

.nopadding {
    padding: 0!important
}

.margin_60 {
    padding-top: 60px;
    padding-bottom: 60px
}

.margin_60_35 {
    padding-top: 20px;
    padding-bottom: 35px
}

@media (max-width:575px) {
    .margin_60_35 {
        padding-top: 30px;
        padding-bottom: 5px
    }
}

.margin_80 {
    padding-top: 80px;
    padding-bottom: 80px
}

@media (max-width:991px) {
    .margin_80 {
        padding-bottom: 60px;
        padding-top: 60px
    }
}

@media (max-width:575px) {
    .margin_80 {
        padding-top: 30px;
        padding-bottom: 30px
    }
}

.margin_80_55 {
    padding-top: 80px;
    padding-bottom: 55px
}

@media (max-width:991px) {
    .margin_80_55 {
        padding-top: 60px;
        padding-bottom: 35px
    }
}

@media (max-width:575px) {
    .margin_80_55 {
        padding-top: 45px;
        padding-bottom: 15px
    }
}

.margin_80_0 {
    padding-top: 80px
}

@media (max-width:991px) {
    .margin_80_0 {
        padding-top: 60px
    }
}

@media (max-width:575px) {
    .margin_80_0 {
        padding-top: 45px
    }
}

.margin_60_0 {
    padding-top: 60px
}

@media (max-width:991px) {
    .margin_60_0 {
        padding-top: 60px
    }
}

@media (max-width:575px) {
    .margin_60_0 {
        padding-top: 45px
    }
}

.margin_30_95 {
    padding-top: 30px;
    padding-bottom: 95px
}

@media (max-width:991px) {
    .margin_30_95 {
        padding-top: 15px;
        padding-bottom: 35px
    }
    .hidden_tablet {
        display: none!important
    }
}

@media (max-width:575px) {
    .margin_30_95 {
        padding-top: 5px;
        padding-bottom: 30px
    }
}

@media (max-width:767px) {
    .hidden_mobile {
        display: none!important
    }
}

.animated .block-reveal {
    height: auto;
    overflow: hidden;
    position: relative;
    width: 100%
}

.block-horizzontal,
.block-vertical {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 10
}

.animated .block-horizzontal {
    animation: reveal_left_to_right 1s ease-in-out;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    background: #0054a6
}

.animated .block-vertical {
    animation: reveal_top_to_bottom 1s ease-in-out;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    background: #0054a6
}

.block-reveal img {
    transition: all .3s ease-in-out;
    opacity: 0
}

.animated .block-reveal img {
    animation: color .5s ease-in-out;
    animation-delay: .5s;
    -webkit-animation-delay: .5s;
    -moz-animation-delay: .5s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden
}

@keyframes reveal_left_to_right {
    0% {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%)
    }
    50% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }
    100% {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@keyframes reveal_top_to_bottom {
    0% {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    50% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0)
    }
    100% {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@keyframes color {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

#cookie-bar {
    background: rgba(0, 0, 0, .8);
    height: auto;
    line-height: 24px;
    color: #eee;
    padding: 15px 0;
    font-size: 13px;
    font-size: .8125rem;
    font-weight: 500
}

#cookie-bar p {
    margin: 0;
    padding: 0
}

#cookie-bar a {
    color: #fff;
    display: inline-block;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    text-decoration: none;
    padding: 2px 10px;
    margin-left: 8px
}

#cookie-bar .cb-enable {
    background: #32a067
}

#cookie-bar .cb-enable:hover {
    background: #fff;
    color: #0054a6
}

#cookie-bar .cb-disable {
    background: #900
}

#cookie-bar .cb-disable:hover {
    background: #b00
}

#cookie-bar .cb-policy {
    background: #fc5b62
}

#cookie-bar .cb-policy:hover {
    background: #fff;
    color: #0054a6
}

#cookie-bar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999
}

#sign-in-dialog,
#sign-in-dialog .form-group {
    position: relative
}

#cookie-bar.fixed.bottom {
    bottom: 0;
    top: auto
}

@media (max-width:767px) {
    #cookie-bar a {
        display: block;
        max-width: 150px;
        margin: auto auto 5px
    }
    #cookie-bar {
        padding: 5px 0
    }
}

#sign-in-dialog {
    background: #fff;
    padding: 0 30px 30px;
    text-align: left;
    max-width: 400px;
    margin: 40px auto;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px
}

#lang-dialog .mfp-close,
#sign-in-dialog .mfp-close {
    color: #666;
    background-color: #e4e4e4;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    top: 12px;
    right: 20px;
    width: 32px;
    height: 32px;
    line-height: 32px
}

#lang-dialog .mfp-close:hover,
#sign-in-dialog .mfp-close:hover {
    color: #fff;
    background-color: #66676b
}

#sign-in-dialog .mfp-close:before {
    font-size: 24px;
    content: "\4d"
}

.small-dialog-header {
    font-size: 18px;
    width: calc(100% + 60px);
    position: relative;
    left: -30px;
    top: 0;
    -webkit-border-radius: 4px 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px 4px;
    border-radius: 4px;
    display: inline-block;
    background-color: #f6f6f6;
    padding: 18px 20px 15px;
    margin-bottom: 30px
}

.small-dialog-header h3 {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 500
}

#sign-in-dialog .form-group input.form-control {
    padding-left: 40px
}

#sign-in-dialog .form-group i {
    font-size: 21px;
    font-size: 1.3125rem;
    position: absolute;
    left: 12px;
    top: 34px;
    color: #ccc;
    width: 25px;
    height: 25px;
    display: block;
    font-weight: 400!important
}

#sign-in-dialog .form-group i.icon_lock_alt {
    top: 36px
}

.my-toggle {
    background: #fff;
    border: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    color: #888;
    font-size: 10px;
    font-size: .625rem;
    font-weight: 700;
    margin-right: 5px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    text-transform: uppercase;
    -moz-appearance: none;
    -webkit-appearance: none
}

#forgot_pw label,
#style-switcher h6,
a#forgot {
    font-weight: 500
}

.my-toggle:focus,
.my-toggle:hover {
    background-color: #eee;
    color: #555;
    outline: transparent
}

.checkboxes label,
a#forgot {
    color: #999
}

.hideShowPassword-wrapper {
    width: 100%!important
}

a#forgot {
    font-size: 13px;
    font-size: .8125rem
}

#forgot_pw {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    min-height: 460px;
    display: none
}

.sign-in-wrapper {
    position: relative;
    height: 100%
}

a.social_bt {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    min-width: 200px;
    display: block;
    padding: 12px;
    line-height: 1;
    position: relative;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

a.social_bt:hover {
    -webkit-filter: brightness(115%);
    filter: brightness(115%)
}

a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
    font-family: ElegantIcons;
    position: absolute;
    left: 12px;
    top: 10px;
    font-size: 1rem;
    color: #fff
}

a.social_bt.facebook {
    background-color: #3B5998
}

a.social_bt.facebook:before {
    content: "\e093"
}

a.social_bt.google {
    background-color: #DC4E41
}

a.social_bt.google:before {
    content: "\e096";
    top: 12px
}

.divider {
    height: 1px;
    margin: 30px 0 20px;
    background-color: #e1e8ed
}

.divider span,
.switchery {
    background-color: #fff;
    display: inline-block;
    position: relative
}

.divider span {
    top: -20px;
    padding: 10px;
    font-style: italic
}

.mfp-bottom-bar {
    padding: 0 10px!important
}

.mfp-counter {
    right: 10px!important;
    color: #fff!important
}

.mfp-bg {
    opacity: .8!important
}

.switchery {
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    height: 30px;
    width: 50px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: content-box;
    background-clip: content-box
}

.switchery>small {
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    height: 30px;
    position: absolute;
    top: 0;
    width: 30px
}

.switchery-small {
    border-radius: 20px;
    height: 20px;
    width: 33px
}

.switchery-small>small {
    height: 20px;
    width: 20px
}

.switchery-large {
    border-radius: 40px;
    height: 40px;
    width: 66px
}

.switchery-large>small {
    height: 40px;
    width: 40px
}

.applyBtn.btn.btn-sm.btn-primary {
    background-color: #fc5b62;
    border: 0
}

.drp-selected {
    display: none!important
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #0054a6!important
}

#style-switcher {
    background: #fff;
    width: 205px;
    position: fixed;
    top: 205px;
    z-index: 99;
    left: -205px;
    border-radius: 0 0 4px;
    -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 15px rgba(0, 0, 0, .175)
}

#style-switcher h3 {
    color: #fff;
    font-size: 13px;
    margin: 10px 0;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0
}

#style-switcher ul {
    list-style: none;
    padding: 0;
    margin-bottom: 22px
}

#style-switcher div {
    padding: 6px 18px
}

#style-switcher h6 {
    background: #f8f8f8;
    color: #333;
    font-size: 14px;
    line-height: 46px;
    padding: 0 0 0 20px;
    margin-top: 0
}

#style-switcher h6 a {
    background-color: #f8f8f8;
    display: block;
    height: 46px;
    position: absolute;
    right: -46px;
    top: 0;
    width: 46px;
    border-radius: 0 4px 4px 0;
    font-size: 22px;
    color: #fff;
    text-align: center
}

#style-switcher h6 a i {
    position: relative;
    top: 4px;
    color: #333
}

.colors {
    list-style: none;
    margin: 0 0 10px;
    overflow: hidden
}

.colors li {
    float: left;
    margin: 4px 2px 0
}

.colors li a {
    display: block;
    width: 17px;
    height: 17px;
    border-radius: 3px
}

.default {
    background: #fc5b62
}

.aqua {
    background: #1cbbb4
}

.green_switcher {
    background: #82ca9c
}

.orange {
    background: #f7941d
}

.blue {
    background: #004dda
}

.colors li a.active {
    box-shadow: 0 0 0 2px #fff inset
}
.tickets-block {
    background-color: #393f47;
    padding: 30px;
    font-size: 18px;
    letter-spacing: 0.2px
}
.tickets-block p {
    margin-bottom: -2px;
}
.tickets-block p, b {
    color: #fff;
    font-size: 15px
}
.tickets-block b {
    font-size: 30px;
}
.tickets-block img {
    max-width: 70px;
    margin-bottom: 10px
}

@media only screen and (max-width:1024px) {
    .purchase-box {
        padding-top: 30px;
        margin-bottom: 60px;
    }
}
@media only screen and (max-width: 996px) {
    .custom-search-input-2 input[type=submit].btn_search {
        margin-bottom: 15px;
    }
    .tickets-block {
        font-size: 16px;
    }
    .tickets-block img {
        margin: 15px 0;
    }
}

.mm {
    margin-top: 85px;
    min-height: 550px;
}
#whatever div {
    display: inline;
    margin: 0 1em 0 1em;
    width: 30%;
  }
  .react-fetch-progress-bar {
    backgroundColor: red !important;
    height: 10px;
  }

 .rowBoards{display:flex; flex-direction:row;}

 #containerMaps {
    width:100%;
    text-align:center;
}

#containerMaps > div {
    width: calc(100% / 6);  
    display: inline-block;
    vertical-align: top;
    text-align:center;
    margin:2%;    
    padding:20px;
}


#containerMaps2 {
    width:100%;
    text-align:center;
}

#containerMaps2 > div {
    width: calc(100% / 10);  
    display: inline-block;
    vertical-align: top;
    text-align:center;
    margin:2%;    
}

.centered {
    height: 20vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rowC{display:flex; flex-direction:row;}

  .flexbox-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}

.myForm {
    max-width: 1000px; 
    display: flex;
    flex-wrap: wrap;
  }

  #test{
    position: static;
   
    left: 1000.5px;
  }